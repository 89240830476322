import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  AuditTool,
  AuditToolElementReview,
  AuditToolFactorReviewDoc,
  AuditToolRelatedDoc,
  Factor,
} from "../types";
import { DocViewerContext } from "./DocViewerContext";
import { generateFactorDocReview, getAuditTool } from "../utils/apiCalls";
import { AuthContext } from "@propelauth/react/dist/types/AuthContext";
import { useAuthInfo } from "@propelauth/react";

type AuditFactorReview = {
  review_summary: string;
  review_docs: AuditToolFactorReviewDoc[];
  error?: string | null;
};
type AuditToolContextType = {
  auditTool: AuditTool | null;
  setAuditTool: React.Dispatch<React.SetStateAction<AuditTool | null>>;
  fetchAuditTool: (auditToolId: string) => Promise<void>;
  activeFactor: Factor | null;
  setActiveFactor: React.Dispatch<React.SetStateAction<Factor | null>>;
  activeDoc: AuditToolRelatedDoc | null;
  setActiveDoc: React.Dispatch<
    React.SetStateAction<AuditToolRelatedDoc | null>
  >;
  auditFactorReview: Record<Factor["id"], AuditFactorReview>;
  setAuditFactorReview: React.Dispatch<
    React.SetStateAction<Record<Factor["id"], AuditFactorReview>>
  >;
  handleAuditFactor: (factor: Factor, doc: AuditToolRelatedDoc) => void;
  findFactorNeighbors: (factor: Factor) => {
    previous: Factor | boolean;
    next: Factor | boolean;
  };
};

export const AuditToolContext = createContext<AuditToolContextType>({
  auditTool: null,
  setAuditTool: () => {},
  fetchAuditTool: (id: string) => Promise.resolve(),
  activeFactor: null,
  setActiveFactor: () => {},
  activeDoc: null,
  setActiveDoc: () => {},
  auditFactorReview: {},
  setAuditFactorReview: () => {},
  handleAuditFactor: () => {},
  findFactorNeighbors: (factor: Factor) => ({
    previous: false,
    next: false,
  }),
});

export const AuditToolProvider = ({ children }: { children: ReactNode }) => {
  const { setDocToView } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();
  const [auditTool, setAuditTool] = useState<AuditTool | null>(null);
  const [activeFactor, setActiveFactor] = useState<Factor | null>(null);
  const [activeDoc, setActiveDoc] = useState<AuditToolRelatedDoc | null>(null);
  const [auditFactorReview, setAuditFactorReview] = useState<
    Record<
      Factor["id"],
      {
        review_summary: string;
        review_docs: AuditToolFactorReviewDoc[];
        error?: string | null;
      }
    >
  >({});
  const fetchAuditTool = async (auditToolId: string) => {
    const auditTool = await getAuditTool(
      auditToolId,
      authInfo.accessToken ?? ""
    );
    setAuditTool(auditTool);
  };

  const sortFactors = (factors: Factor[]) => {
    return factors.sort((a, b) => {
      // First compare standard_index
      const standardCompare = (a.standard_index ?? "").localeCompare(
        b.standard_index ?? ""
      );
      if (standardCompare !== 0) return standardCompare;

      // Then compare element_index
      const elementCompare = a.element_index.localeCompare(b.element_index);
      if (elementCompare !== 0) return elementCompare;

      // Finally compare factor_index
      return a.factor_index.localeCompare(b.factor_index);
    });
  };

  const findFactorNeighbors = (factor: Factor) => {
    const sortedFactors = sortFactors(auditTool?.factors ?? []);
    const index = sortedFactors.findIndex((f) => f.id === factor.id);
    return {
      previous: index === 0 ? false : sortedFactors[index - 1],
      next:
        index === sortedFactors.length - 1 ? false : sortedFactors[index + 1],
    };
  };

  const handleAuditFactor = async (
    factor: Factor,
    doc: AuditToolRelatedDoc
  ) => {
    setActiveFactor(factor);
    setActiveDoc(doc);
    setDocToView({
      docId: doc.doc_id,
    });
  };
  return (
    <AuditToolContext.Provider
      value={{
        auditTool,
        setAuditTool,
        fetchAuditTool,
        activeFactor,
        setActiveFactor,
        activeDoc,
        setActiveDoc,
        auditFactorReview,
        setAuditFactorReview,
        handleAuditFactor,
        findFactorNeighbors,
      }}
    >
      {children}
    </AuditToolContext.Provider>
  );
};
