import {
  AuditToolBreadcrumbs,
  SharepointUrlPopover,
} from "./AuditToolDashboard";
import { useNavigate, useParams } from "react-router-dom";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import {
  ResizableHandle,
  ResizablePanel,
} from "../../shadcn/components/resizable";
import { ResizablePanelGroup } from "../../shadcn/components/resizable";
import { Layout } from "../../components/Layout";
import { Button } from "../../shadcn/components/button";
import {
  generateFactorDocReview,
  getAuditFactor,
  getAuditFactorDocReview,
  getAuditTool,
  getAuditToolSharepointFolderInfo,
  loadAuditToolDocs,
  updateAuditFactor,
} from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import {
  auditFactorAssessmentStatusLabelClasses,
  AuditFactorDocReviewResponse,
  auditFactorAssessment,
  AuditToolFactorReviewDoc,
  Factor,
  auditFactorStatus,
  auditFactorStatusLabelClasses,
  AuditTool,
  FileIndex,
  AuditToolElementReview,
  AuditFactorAssessment,
  AuditFactorStatus,
} from "../../types";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import { DocViewerCitation } from "../../components/DocViewer";
import React from "react";
import { toast } from "sonner";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { Checkbox } from "../../shadcn/components/checkbox";
import { MarkdownToJSX } from "./components";
import { StatusSelector } from "../../components/StatusSelector";
import { createFileIndexFromAuditTool } from "./utils";
import { FolderDisplay } from "../../components/FileSelectorDialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import {
  ArrowLeft,
  CheckCircle,
  Loader2,
  RefreshCw,
  Sparkle,
  Sparkles,
} from "lucide-react";
import { Sheet, SheetContent, SheetTitle } from "../../shadcn/components/sheet";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { ElementReview } from "./ElementReview";
import { FileTree } from "../../components/FileTreeDisplay";
import { Badge } from "../../shadcn/components/badge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/components/accordion";
import { localizeDate, TimeAgo } from "../../utils/format";
import Markdown from "react-markdown";

const DocumentReview = ({
  reviewDocs,
  isAuditGenerating,
  setOpenDocSheet,
}: {
  reviewDocs: AuditToolFactorReviewDoc[];
  isAuditGenerating: boolean;
  setOpenDocSheet: (open: boolean) => void;
}) => {
  const { setDocToView, setCitations, docToView, setPageNumber } =
    useContext(DocViewerContext);
  const [activeCitation, setActiveCitation] = useState<
    PdfCitation["id"] | null
  >(null);
  const [prevReviewDocsLength, setPrevReviewDocsLength] = useState(0);
  const lastDocRef = React.useRef<HTMLDivElement>(null);
  const loaderRef = React.useRef<HTMLDivElement>(null);
  const handleCitationClick = (
    reviewDoc: AuditToolFactorReviewDoc,
    citationId: string
  ) => {
    setActiveCitation(citationId);
    const citations = reviewDoc.citations.map((citation) => ({
      id:
        citation.id ??
        `doc-${reviewDoc.doc_id}-citation-${citation.match_index}`,
      match: citation.match,
      exactMatch: true,
      page: citation.page,
      match_index: citation.match_index,
      className: `bg-highlight-${citation.id === citationId ? "default" : "inactive"}`,
      classNameOnHover: `bg-highlight-default`,
      // citationTooltip:
      //   citation.id === citationId ? (
      //     <div className="p-2">
      //       <div className="font-semibold mb-1">Citation</div>
      //     </div>
      //   ) : undefined,
      onClick: () => {
        handleCitationClick(
          reviewDoc,
          citation.id ??
            `doc-${reviewDoc.doc_id}-citation-${citation.match_index}`
        );
      },
    }));
    setCitations(citations);
    setPageNumber(
      reviewDoc.citations.find((c) => c.id === citationId)?.page ?? 1
    );
    if (reviewDoc.doc_id !== docToView?.docId) {
      setDocToView({ docId: reviewDoc.doc_id });
    }
    setOpenDocSheet(true);
  };

  useEffect(() => {
    if (reviewDocs.length > prevReviewDocsLength && isAuditGenerating) {
      loaderRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      setPrevReviewDocsLength(reviewDocs.length);
    }
  }, [lastDocRef.current]);

  return (
    <div className="mt-4">
      <h2 className="font-semibold text-lg">
        Document Reviews
        <Badge className="ml-2 text-xs" variant="default">
          {reviewDocs.length}
        </Badge>
      </h2>
      <div className="space-y-4">
        {reviewDocs.map((doc, docIndex) => (
          <div
            key={docIndex}
            className="border rounded-md p-4 bg-white"
            ref={docIndex === reviewDocs.length - 1 ? lastDocRef : null}
          >
            <h3
              className="font-semibold hover:text-blue-800 underline cursor-pointer"
              onClick={() =>
                handleCitationClick(doc, doc.citations[0]?.id ?? "none")
              }
            >
              {doc.doc_name}
            </h3>
            <div className="text-sm mb-2">
              {renderReviewWithCitations(
                doc.review || "",
                doc.citations,
                (citationId) => handleCitationClick(doc, citationId)
              )}
            </div>
          </div>
        ))}
        {isAuditGenerating && (
          <div className="flex justify-center" ref={loaderRef}>
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
          </div>
        )}
      </div>
    </div>
  );
};

const renderReviewWithCitations = (
  review: string,
  citations: PdfCitation[],
  onClick: (citationId: string) => void
) => {
  return review
    .split(/(<citation_ids>.*?<\/citation_ids>)/g)
    .map((part, index) => {
      const match = part.match(/<citation_ids>(.*?)<\/citation_ids>/);
      if (match) {
        const citationIds = match[1];
        const citationIdsArray = citationIds.split(",");
        return (
          <>
            <div className="flex items-center gap-2 mt-2">
              <span className="text-sm text-gray-600 font-medium">
                Supporting Citations:
              </span>
              <div className="flex flex-wrap gap-1">
                {citationIdsArray.map((citationId, index) => {
                  const citationIndex = citations.findIndex(
                    (c) => c.id === citationId
                  );
                  if (citationIndex !== -1) {
                    return (
                      <Badge
                        key={citationId}
                        onClick={() => onClick(citationId)}
                        className="cursor-pointer hover:bg-gray-100 transition-colors px-2 py-0.5"
                        variant="default"
                      >
                        Page {citations[citationIndex].page}
                      </Badge>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </>
        );
      }
      return part.split("\n").map((line, i) => (
        <Fragment key={i}>
          {line}
          <br />
        </Fragment>
      )); // Split regular text on newlines and add breaks
    });
};

const UpdateFactorStatus = ({ factor }: { factor: Factor }) => {
  const authInfo = useAuthInfo();
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const { setAuditTool } = useContext(AuditToolContext);
  return (
    <StatusSelector
      status={factor.status || ""}
      hideLabel={true}
      options={Object.entries(auditFactorStatus).map(([key, value]) => ({
        value: key,
        label: value,
        className: auditFactorStatusLabelClasses[key as AuditFactorStatus],
      }))}
      handleStatusChange={async (value) => {
        if (!auditToolId || !authInfo.accessToken) return;
        const updatedFactor: Factor = {
          ...factor,
          status: value as AuditFactorStatus,
        };
        try {
          await updateAuditFactor(
            auditToolId,
            updatedFactor,
            authInfo.accessToken
          );
          toast.success("Factor status updated successfully");
        } catch (error) {
          toast.error("Failed to update factor status");
        }
        setAuditTool((prevAuditTool) => {
          if (!prevAuditTool) {
            return null;
          }
          return {
            ...prevAuditTool,
            factors: prevAuditTool.factors.map((f) =>
              f.id === updatedFactor.id ? updatedFactor : f
            ),
          };
        });
      }}
    />
  );
};

const UpdateFactorAssessment = ({ factor }: { factor: Factor }) => {
  const authInfo = useAuthInfo();
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const { setAuditTool } = useContext(AuditToolContext);
  return (
    <StatusSelector
      status={factor.assessment || auditFactorAssessment.na}
      hideLabel={true}
      options={Object.entries(auditFactorAssessment).map(([key, value]) => ({
        value: key,
        label: value,
        className:
          auditFactorAssessmentStatusLabelClasses[key as AuditFactorAssessment],
      }))}
      handleStatusChange={async (value) => {
        try {
          if (!auditToolId || !authInfo.accessToken) return;
          const updatedFactor: Factor = {
            ...factor,
            assessment: value as AuditFactorAssessment,
            status: "done",
          };
          try {
            await updateAuditFactor(
              auditToolId,
              updatedFactor,
              authInfo.accessToken
            );
            toast.success("Factor assessment updated successfully");
          } catch (error) {
            toast.error("Failed to update factor assessment");
          }
          setAuditTool((prevAuditTool) => {
            if (!prevAuditTool) {
              return null;
            }
            return {
              ...prevAuditTool,
              factors: prevAuditTool.factors.map((f) =>
                f.id === updatedFactor.id ? updatedFactor : f
              ),
            };
          });
        } catch (error) {
          toast.error("Failed to update factor assessment");
        }
      }}
    />
  );
};

const RelatedDocsDialog = ({
  auditTool,
  onSelect,
  syncSharepoint,
  isSharepointSynced,
  isSharepointLoading,
  alreadySelectedRelatedDocIds = [],
  alreadyReviewedDocIds = [],
}: {
  auditTool: AuditTool;
  syncSharepoint: () => Promise<void>;
  onSelect: (relatedDocIds: string[]) => void;
  alreadySelectedRelatedDocIds: string[];
  isSharepointLoading: boolean;
  isSharepointSynced: boolean;
  alreadyReviewedDocIds?: string[];
}) => {
  const { activeModalRef, setActiveModalRef } = useModalContext();
  const relatedDocRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedRelatedDocIds, setSelectedRelatedDocIds] = useState<string[]>(
    alreadySelectedRelatedDocIds
  );
  const [fileIndex, setFileIndex] = useState<FileIndex>(
    createFileIndexFromAuditTool(
      auditTool,
      selectedRelatedDocIds,
      alreadyReviewedDocIds
    )
  );
  const handleSelect = (fileIndex: FileIndex) => {
    if (!fileIndex.id) return;
    setSelectedRelatedDocIds((prev: string[]) => {
      const id = fileIndex.id as string;
      return prev.includes(id)
        ? prev.filter((docId) => docId !== id)
        : [...prev, id];
    });
  };

  useEffect(() => {
    setFileIndex(
      createFileIndexFromAuditTool(
        auditTool,
        selectedRelatedDocIds,
        alreadyReviewedDocIds
      )
    );
  }, [selectedRelatedDocIds, alreadyReviewedDocIds, auditTool]);
  useEffect(() => {
    if (open) {
      setActiveModalRef(relatedDocRef);
    } else {
      setActiveModalRef(null);
    }
  }, [open]);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Select Docs to Review</Button>
      </DialogTrigger>
      <DialogContent ref={relatedDocRef}>
        <DialogHeader>
          <DialogTitle>Select Related Documents for Audit</DialogTitle>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            {isSharepointLoading ? (
              <>
                <span>Syncing with SharePoint</span>
                <Loader2 className="h-4 w-4 animate-spin" />
              </>
            ) : isSharepointSynced ? (
              <div className="flex items-center gap-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>
                  Synced with SharePoint{" "}
                  <TimeAgo
                    timestamp={auditTool.related_docs_last_updated_at ?? ""}
                  />
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={(e) => {
                    e.preventDefault();
                    syncSharepoint();
                  }}
                  className="h-6 w-6 hover:bg-gray-100 rounded-full"
                >
                  <RefreshCw className="h-3 w-3 text-gray-400" />
                </Button>
              </div>
            ) : null}
          </div>
        </DialogHeader>
        <div className="space-y-2 max-h-[calc(100vh-200px)] overflow-y-auto">
          <FileTree
            files={fileIndex.children}
            folderName={fileIndex.name}
            onSelectFile={handleSelect}
          />
        </div>
        <div className="flex justify-end mt-4">
          <Button
            onClick={() => {
              onSelect(selectedRelatedDocIds);
              setOpen(false);
            }}
          >
            Select Documents ({selectedRelatedDocIds.length})
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const AuditToolFactorAuditView = ({}: {}) => {
  const authInfo = useAuthInfo();
  const reviewSummaryRef = useRef<HTMLDivElement>(null);
  const {
    auditTool,
    setAuditTool,
    fetchAuditTool,
    setActiveFactor,
    activeFactor,
    auditFactorReview,
    setAuditFactorReview,
    findFactorNeighbors,
  } = useContext(AuditToolContext);
  const navigate = useNavigate();
  const { activeModalRef, setActiveModalRef } = useModalContext();
  const { docToView, setCitations } = useContext(DocViewerContext);
  const { auditToolId, factorId } = useParams<{
    auditToolId: string;
    factorId: string;
  }>();
  const docSheetRef = useRef<HTMLDivElement>(null);
  setActiveModalRef(docSheetRef);
  const [openDocSheet, setOpenDocSheet] = useState(false);
  const [isAuditGenerating, setIsAuditGenerating] = useState(false);
  const [isSharepointSynced, setIsSharepointSynced] = useState(false);
  const [isSharepointLoading, setIsSharepointLoading] = useState(false);
  const [selectedRelatedDocIds, setSelectedRelatedDocIds] = useState<string[]>(
    []
  );
  const [factorNeighbors, setFactorNeighbors] = useState<{
    previous: Factor | boolean;
    next: Factor | boolean;
  }>(
    activeFactor
      ? findFactorNeighbors(activeFactor)
      : { previous: false, next: false }
  );
  const syncSharepoint = async (override = false) => {
    if (!auditTool || isSharepointLoading) return;
    const lastUpdated = localizeDate(
      auditTool.related_docs_last_updated_at ?? ""
    );
    const currentTime = new Date();
    const diffInMinutes =
      (currentTime.getTime() - lastUpdated.getTime()) / (1000 * 60);

    setIsSharepointLoading(true);
    try {
      // const sharepointFolderInfo = await getAuditToolSharepointFolderInfo(
      //   auditTool.id,
      //   authInfo.accessToken ?? null
      // );
      if (
        !auditTool.related_docs_last_updated_at ||
        override ||
        diffInMinutes > 3
      ) {
        console.log(
          "syncing sharepoint",
          lastUpdated,
          auditTool.related_docs_last_updated_at,
          currentTime,
          diffInMinutes
        );
        const related_docs = await loadAuditToolDocs(
          auditTool.id,
          authInfo.accessToken ?? null
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setAuditTool((prevAuditTool) => {
          if (!prevAuditTool) return null;
          return {
            ...prevAuditTool,
            related_docs_last_updated_at: new Date()
              .toISOString()
              .replace("Z", "")
              .replace("T", " "),
            related_docs: related_docs ?? [],
          };
        });
      }
      setIsSharepointSynced(true);
    } catch (error) {
      console.error("Error syncing sharepoint", error);
    } finally {
      setIsSharepointLoading(false);
    }
  };
  const fetchReview = async () => {
    console.log(activeFactor);
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    const review = await getAuditFactorDocReview(
      auditToolId,
      factorId,
      authInfo.accessToken ?? null
    );
    const reviewDocs = review.map(
      (reviewDoc: AuditFactorDocReviewResponse) => ({
        id: reviewDoc.id,
        audit_tool_related_doc_id: reviewDoc.audit_tool_related_doc_id,
        doc_id: reviewDoc.audit_tool_related_doc.doc.id,
        doc_name: reviewDoc.audit_tool_related_doc.doc.doc_name,
        factor_id: reviewDoc.audit_factor_id,
        review: reviewDoc.review_summary,
        review_status: reviewDoc.review_summary,
        citations: reviewDoc.citations.map((citation: any) => ({
          id: citation.citation.id,
          match: citation.citation.text,
          exactMatch: true,
          page: citation.citation.page,
          match_index: citation.citation.match_index ?? undefined,
        })),
        review_status_updated_by: null,
        review_status_updated_at: null,
      })
    );
    setAuditFactorReview((prev) => {
      return {
        ...prev,
        [factorId ?? ""]: {
          review_summary: activeFactor?.narrative ?? "",
          review_docs: reviewDocs,
        },
      };
    });
    setSelectedRelatedDocIds((selected) => {
      return selected.filter(
        (id) => !reviewDocs.some((doc) => doc.audit_tool_related_doc_id === id)
      );
    });
  };

  useEffect(() => {
    setActiveModalRef(docSheetRef);
  }, [docSheetRef]);
  useEffect(() => {
    if (activeFactor) {
      fetchReview();
      setFactorNeighbors(findFactorNeighbors(activeFactor));
    }
  }, [activeFactor]);
  useEffect(() => {
    if (auditTool) {
      setActiveFactor(
        auditTool.factors.find((factor) => factor.id === factorId) ?? null
      );
      syncSharepoint();
    }
  }, [auditTool]);
  useEffect(() => {
    if (auditToolId) {
      fetchAuditTool(auditToolId);
    }
  }, [auditToolId]);
  useEffect(() => {
    if (reviewSummaryRef.current && isAuditGenerating) {
      reviewSummaryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [
    reviewSummaryRef.current,
    auditFactorReview[factorId ?? ""]?.review_summary?.length,
  ]);
  useEffect(() => {
    if (docToView?.docId) {
      setOpenDocSheet(true);
    }
  }, [docToView?.docId]);

  const handleSubmitAudit = async (relatedDocIds: string[]) => {
    if (!auditToolId || !factorId || !authInfo.accessToken) return;
    try {
      setIsAuditGenerating(true);
      for await (const output of generateFactorDocReview(
        auditToolId,
        factorId,
        relatedDocIds,
        authInfo.accessToken ?? null
      )) {
        const auditResponse = {
          ...output,
          review_docs: output?.review_docs.map((doc) => {
            return {
              ...doc,
              factor_id: doc.audit_question_id,
              citations: doc.citations.map((citation, index) => {
                return {
                  id: citation.id ?? `doc-${doc.doc_id}-citation-${index}`,
                  match: citation.text,
                  exactMatch: true,
                  page: citation.page,
                  match_index: citation.match_index ?? undefined,
                };
              }),
            };
          }),
        };
        setAuditFactorReview({
          ...auditFactorReview,
          [factorId]: auditResponse,
        });
        setCitations(auditResponse.review_docs[0].citations);
      }
      const factor = await getAuditFactor(
        auditToolId,
        factorId,
        authInfo.accessToken ?? null
      );
      setAuditTool((prevAuditTool) => {
        if (!prevAuditTool) return null;
        return {
          ...prevAuditTool,
          factors: prevAuditTool.factors.map((f) =>
            f.id === factorId ? factor : f
          ),
        };
      });
    } catch (error: any) {
      console.error("There was an error generating the review", error);
    } finally {
      setIsAuditGenerating(false);
    }
  };

  const FactorLinkToolTipContent = ({
    factor,
  }: {
    factor: Factor | boolean;
  }) => {
    return (
      <div className="p-2">
        {factor && typeof factor !== "boolean" ? (
          <>
            <div className="font-medium text-gray-900 mb-1">Go to:</div>
            <div className="text-sm text-gray-700">
              Requirement: {factor.factor_title}
            </div>
            {activeFactor &&
              factor.element_index !== activeFactor.element_index && (
                <div className="text-xs text-gray-500 mt-1">
                  Different element: {factor.element_index}
                </div>
              )}
          </>
        ) : (
          <div className="text-sm text-gray-500">No factor available</div>
        )}
      </div>
    );
  };
  return (
    <Layout pageName="Audit Tool">
      <div>
        <div className="flex flex-row items-center mb-4">
          <AuditToolBreadcrumbs
            auditTool={auditTool}
            factor={activeFactor ?? undefined}
          />
        </div>

        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={60}
            minSize={40}
            maxSize={60}
            id="resource-panel"
            order={2}
          >
            <div className="space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-165px)] overflow-y-auto">
              <div className="space-y-6">
                {activeFactor ? (
                  <>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center justify-between gap-4">
                        <Tooltip>
                          <TooltipTrigger>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const { previous } = factorNeighbors;
                                if (previous && typeof previous !== "boolean") {
                                  navigate(
                                    `/delegate-audit/${auditToolId}/factor/${previous.id}/audit`
                                  );
                                  setActiveFactor(previous);
                                }
                              }}
                              disabled={!factorNeighbors.previous}
                            >
                              Previous
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <FactorLinkToolTipContent
                              factor={factorNeighbors.previous}
                            />
                          </TooltipContent>
                        </Tooltip>
                        <h1 className="text-3xl font-bold tracking-tight bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
                          {activeFactor?.factor_title}
                        </h1>
                        <Tooltip>
                          <TooltipTrigger>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const { next } = factorNeighbors;
                                if (next && typeof next !== "boolean") {
                                  navigate(
                                    `/delegate-audit/${auditToolId}/factor/${next.id}/audit`
                                  );
                                  setActiveFactor(next);
                                }
                              }}
                              disabled={!factorNeighbors.next}
                            >
                              Next
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <FactorLinkToolTipContent
                              factor={factorNeighbors.next}
                            />
                          </TooltipContent>
                        </Tooltip>
                      </div>
                      <div className="text-sm text-gray-500">
                        <span className="font-medium text-gray-600">
                          Source:
                        </span>{" "}
                        {activeFactor?.source} / {activeFactor?.standard_index}{" "}
                        / {activeFactor?.element_index} /{" "}
                        {activeFactor?.factor_index}
                      </div>
                      <Accordion
                        type="single"
                        collapsible
                        className="border rounded-lg"
                      >
                        <AccordionItem
                          value="requirement-info"
                          className="border-none"
                        >
                          <AccordionTrigger className="hover:no-underline hover:bg-gray-50 px-4 py-3">
                            <div className="flex items-center gap-2">
                              <span className="font-medium">
                                Requirement Information
                              </span>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent className="px-4 pb-4">
                            <div className="space-y-4">
                              <div className="text-lg text-gray-700">
                                {activeFactor.factor_description}
                              </div>
                              <div className="text-sm space-y-2 text-gray-600">
                                <MarkdownToJSX
                                  markdown={activeFactor.factor_explanation}
                                />
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="flex flex-col gap-1">
                        <span className="font-medium">Status:</span>
                        <UpdateFactorStatus factor={activeFactor} />
                      </div>
                      <div className="flex flex-col gap-1">
                        <span className="font-medium">Assessment:</span>
                        <UpdateFactorAssessment factor={activeFactor} />
                      </div>
                    </div>
                    <div>
                      {auditTool && (
                        <>
                          <div className="flex justify-between gap-4">
                            <div className="flex gap-4">
                              <SharepointUrlPopover />
                              <RelatedDocsDialog
                                auditTool={auditTool}
                                onSelect={setSelectedRelatedDocIds}
                                syncSharepoint={() => syncSharepoint(true)}
                                isSharepointSynced={isSharepointSynced}
                                isSharepointLoading={isSharepointLoading}
                                alreadySelectedRelatedDocIds={
                                  selectedRelatedDocIds
                                }
                                alreadyReviewedDocIds={auditFactorReview[
                                  factorId ?? ""
                                ]?.review_docs.map(
                                  (doc) => doc.audit_tool_related_doc_id
                                )}
                              />
                              <Tooltip>
                                <TooltipTrigger>
                                  <Button
                                    variant="default"
                                    className="gap-2"
                                    onClick={() =>
                                      handleSubmitAudit(selectedRelatedDocIds)
                                    }
                                    disabled={
                                      selectedRelatedDocIds.length === 0 ||
                                      isAuditGenerating
                                    }
                                  >
                                    <Sparkles
                                      className={`h-4 w-4 ${
                                        selectedRelatedDocIds.length > 0
                                          ? "animate-pulse"
                                          : ""
                                      }`}
                                    />
                                    Audit ({selectedRelatedDocIds.length})
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent className="max-w-sm p-4 bg-white border border-gray-200 rounded-md shadow-md text-sm text-gray-700">
                                  {selectedRelatedDocIds.length === 0 ? (
                                    <div className="text-gray-500 italic pl-4">
                                      <p>No documents selected for audit</p>
                                      <p>
                                        Choose documents by clicking "Select
                                        Docs to Review"
                                      </p>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="font-semibold mb-2">
                                        Documents to be audited:
                                      </div>
                                      <ul className="list-disc list-inside max-h-48 overflow-auto space-y-1 pl-4">
                                        {selectedRelatedDocIds.map(
                                          (related_doc_id) => {
                                            const related_doc =
                                              auditTool.related_docs.find(
                                                (rd) => rd.id === related_doc_id
                                              );
                                            return (
                                              <li
                                                key={related_doc_id}
                                                className="truncate"
                                                title={related_doc?.doc_name}
                                              >
                                                {related_doc?.doc_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </>
                                  )}
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {auditFactorReview[factorId ?? ""] && (
                      <div className="space-y-4">
                        <h2 className="text-lg font-semibold flex items-center gap-2">
                          <span>AI Review</span>
                        </h2>
                        <div className="space-y-2">
                          <div>
                            {isAuditGenerating &&
                            !auditFactorReview[factorId ?? ""]
                              .review_summary ? (
                              <div className="h-[120px] flex items-center justify-center bg-gray-50 rounded-md">
                                <span className="animate-pulse text-lg text-gray-800">
                                  Generating review...
                                </span>
                              </div>
                            ) : !auditFactorReview[factorId ?? ""]
                                .review_summary ? (
                              <div className="h-[120px] flex items-center justify-center bg-gray-50 rounded-md">
                                <span className="text-lg text-gray-500">
                                  To create an AI review, select documents to
                                  review, then click Audit
                                </span>
                              </div>
                            ) : (
                              <div
                                ref={reviewSummaryRef}
                                className="prose max-w-none p-4 bg-gray-50 rounded-md"
                              >
                                <Markdown>
                                  {
                                    auditFactorReview[factorId ?? ""]
                                      .review_summary
                                  }
                                </Markdown>
                              </div>
                            )}
                          </div>
                        </div>
                        <DocumentReview
                          reviewDocs={
                            auditFactorReview[factorId ?? ""].review_docs
                          }
                          isAuditGenerating={isAuditGenerating}
                          setOpenDocSheet={setOpenDocSheet}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div>No factor selected</div>
                )}
              </div>
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle className="mx-4" />
          <ResizablePanel
            defaultSize={40}
            minSize={40}
            maxSize={60}
            id="resource-panel"
            order={1}
          >
            <ElementReview
              auditToolId={auditTool?.id ?? ""}
              fa={activeFactor?.factor_index ?? ""}
              std={activeFactor?.standard_index ?? ""}
              el={activeFactor?.element_index ?? ""}
              isFactorView={true}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
      <Sheet open={openDocSheet} onOpenChange={setOpenDocSheet}>
        <SheetContent style={{ maxWidth: "50vw" }} ref={docSheetRef}>
          <SheetTitle>Audit Tool</SheetTitle>
          {docToView?.docId && (
            <DocViewerCitation
              docId={docToView?.docId}
              className="h-[calc(100vh-130px)]"
              hideAtlasWidget={true}
              parentModalRef={activeModalRef ?? undefined}
            />
          )}
        </SheetContent>
      </Sheet>
    </Layout>
  );
};
