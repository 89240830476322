import { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { AuditTool } from "../../types";
import { useAuthInfo } from "@propelauth/react";
import {
  createAuditTool,
  CreateAuditToolRequest,
  deleteAuditTool,
  getAudits,
  getAuditTools,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import { LoadingView } from "../../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { Input } from "../../shadcn/components/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import { Button } from "../../shadcn/components/button";
import { Calendar } from "../../shadcn/components/calendar";
import { DateRange } from "react-day-picker";
import { FunctionalAreaSelect } from "./components";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Loader2 } from "lucide-react";
export const AuditToolView = () => {
  const authInfo = useAuthInfo();
  const [audits, setAudits] = useState<AuditTool[]>([]);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [isCreateAuditLoading, setIsCreateAuditLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAuditsLoading(true);
    getAuditTools(authInfo.accessToken ?? null).then((audits) => {
      if (audits) {
        setAudits(audits);
      } else {
        toast.error("Failed to fetch audits");
      }
      setAuditsLoading(false);
    });
  }, []);

  const handleCreateAudit = async (request: CreateAuditToolRequest) => {
    setIsCreateAuditLoading(true);
    try {
      const res = await createAuditTool(request, authInfo.accessToken ?? null);
      if (res) {
        toast.success("Audit tool created successfully");
        navigate(`/delegate-audit/${res.id}`);
      } else {
        toast.error("Failed to create audit tool");
      }
    } catch (error) {
      toast.error("Failed to create audit tool");
    } finally {
      setIsCreateAuditLoading(false);
    }
  };

  return (
    <Layout pageName="Audit Tool Dashboard">
      <div className="flex justify-center space-y-2">
        <Dialog>
          <DialogTrigger asChild>
            <button className="bg-black text-white w-[600px] px-4 py-2 rounded-md hover:bg-gray-800">
              Create Audit Tool
            </button>
          </DialogTrigger>
          <AuditToolCreateDialog
            onSubmit={handleCreateAudit}
            isLoading={isCreateAuditLoading}
          />
        </Dialog>
      </div>
      <h2 className="text-2xl font-bold text-center">My Audit Tools</h2>
      <div className="flex justify-center space-y-2">
        {auditsLoading && <LoadingView customText="Loading audits..." />}
        <div className="flex flex-col space-y-2">
          {audits.map((audit) => (
            <div className="flex justify-between items-center" key={audit.id}>
              <Link to={`/delegate-audit/${audit.id}`} className="flex-grow">
                <div className="bg-white w-[600px] p-5 rounded-md hover:bg-gray-200 cursor-pointer">
                  <div className="text-md font-bold">{audit.name}</div>
                </div>
              </Link>
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  if (
                    window.confirm(
                      "Are you sure you want to delete this audit tool?"
                    )
                  ) {
                    const res = await deleteAuditTool(
                      audit.id,
                      authInfo.accessToken ?? null
                    );
                    if (res) {
                      toast.success("Audit tool deleted successfully");
                      setAudits(audits.filter((a) => a.id !== audit.id));
                    } else {
                      toast.error("Failed to delete audit tool");
                    }
                  }
                }}
                className="ml-2 bg-red-500 hover:bg-red-700 text-white px-3 py-2 rounded-md"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        {audits.length === 0 && !auditsLoading && (
          <div className="text-center text-gray-500">No resources found</div>
        )}
      </div>
    </Layout>
  );
};

const AuditToolCreateDialog = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (request: CreateAuditToolRequest) => void;
  isLoading: boolean;
}) => {
  const [auditName, setAuditName] = useState("");
  const [auditDelegate, setAuditDelegate] = useState("");
  const [auditScope, setAuditScope] = useState("");
  const [auditStartDate, setAuditStartDate] = useState<Date | undefined>(
    undefined
  );
  const [auditEndDate, setAuditEndDate] = useState<Date | undefined>(undefined);
  return (
    <DialogContent>
      <DialogTitle>Create Audit Tool</DialogTitle>
      <div className="flex flex-col space-y-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              auditName &&
              auditDelegate &&
              auditScope &&
              auditStartDate &&
              auditEndDate
            ) {
              onSubmit({
                name: auditName,
                delegate: auditDelegate,
                scope: auditScope,
                audit_start_date: auditStartDate.toISOString(),
                audit_end_date: auditEndDate.toISOString(),
              });
            } else {
              toast.error("Please fill in all fields");
            }
          }}
        >
          <div className="space-y-4">
            <div>
              <label htmlFor="auditName">Audit Name</label>
              <Input
                type="text"
                id="auditName"
                value={auditName}
                onChange={(e) => setAuditName(e.target.value)}
                placeholder="Enter audit name"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="auditDelegate">Audit Delegate</label>
              <Input
                type="text"
                id="auditDelegate"
                value={auditDelegate}
                onChange={(e) => setAuditDelegate(e.target.value)}
                placeholder="Enter audit delegate"
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="auditScope">Audit Scope</label>
              <FunctionalAreaSelect
                functionalArea={auditScope}
                handleChangeFunctionalArea={(functionalArea) =>
                  setAuditScope(functionalArea)
                }
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="auditStartDate">Audit Lookback Period</label>
              <div className="flex items-center gap-4">
                <DatePicker
                  value={auditStartDate ? dayjs(auditStartDate) : null}
                  onChange={(value) =>
                    setAuditStartDate(value?.toDate() ?? undefined)
                  }
                  disableOpenPicker={true}
                />
                <DatePicker
                  value={auditEndDate ? dayjs(auditEndDate) : null}
                  onChange={(value) =>
                    setAuditEndDate(value?.toDate() ?? undefined)
                  }
                  disableOpenPicker={true}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Creating..." : "Create"}
            </Button>
            {isLoading && (
              <div className="flex flex-row items-center gap-2 text-sm text-gray-500">
                <Loader2 className="animate-spin h-4 w-4" />
                Creating Audit Tool and Sharepoint Folders
              </div>
            )}
          </div>
        </form>
      </div>
    </DialogContent>
  );
};
