import React, { ReactNode, useRef } from "react";
import { Textarea, TextareaProps } from "../shadcn/components/textarea";

export type AutoSavingTextAreaProps = {
  textareaProps: TextareaProps & React.RefAttributes<HTMLTextAreaElement>;
  onSave: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  updatedText?: ReactNode;
  className?: string;
};
export const AutoSavingTextArea: React.FC<AutoSavingTextAreaProps> = ({
  textareaProps,
  onSave,
  updatedText,
  className,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const updateTextTimeout = async (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      onSave(e);
    }, 1000);
  };
  return (
    <div className={className}>
      <Textarea
        value={textareaProps.value}
        {...textareaProps}
        onChange={(e) => {
          textareaProps.onChange && textareaProps.onChange(e);
          updateTextTimeout(e);
        }}
      />
      <div>{updatedText && updatedText}</div>
    </div>
  );
};
