import React, { useEffect, useState } from "react";
import {
  ChevronRight,
  Folder,
  FolderOpen,
  FileIcon,
  CheckCircle2,
} from "lucide-react";
import { cn } from "../shadcn/lib/utils";
import { Checkbox } from "../shadcn/components/checkbox";
import { FileIndex } from "../types";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";

function countFiles(node: FileIndex): number {
  if (node.type === "file") return 1;
  return node.children?.reduce((sum, child) => sum + countFiles(child), 0) ?? 0;
}

function hasSelectedChild(node: FileIndex): boolean {
  if (node.type === "file" && (node.isSelected || node.isReviewed)) return true;
  return node.children?.some(hasSelectedChild) ?? false;
}

type FileTreeNodeProps = {
  node: FileIndex;
  level: number;
  onSelectFile: (file: FileIndex) => void;
};

const FileTreeNode: React.FC<FileTreeNodeProps> = ({
  node,
  level,
  onSelectFile,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (node.type === "folder" && hasSelectedChild(node)) {
      setIsOpen(true);
    }
  }, []);

  if (node.type === "folder") {
    return (
      <div className="flex flex-col">
        <div
          className="flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 transition-colors"
          style={{ paddingLeft: `${level * 1.25}rem` }}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <ChevronRight
            className={cn(
              "h-[16px] w-[16px] transition-transform text-gray-500",
              isOpen ? "rotate-90" : ""
            )}
          />
          {isOpen ? (
            <FolderOpen className="h-[16px] w-[16px] text-gray-500 ml-1" />
          ) : (
            <Folder className="h-[16px] w-[16px] text-gray-500 ml-1" />
          )}
          <span className="ml-1.5 font-medium text-gray-700">
            {node.name}{" "}
            <span className="text-gray-400 text-sm font-normal">
              ({countFiles(node)} {countFiles(node) === 1 ? "file" : "files"})
            </span>
          </span>
        </div>

        {isOpen && (
          <div className="flex flex-col">
            {node.children.map((child) => (
              <FileTreeNode
                key={child.id || child.name}
                node={child}
                level={level + 1}
                onSelectFile={onSelectFile}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        "flex items-center py-1.5 transition-colors hover:bg-gray-50 rounded-md",
        node.isSelected ? "bg-blue-50 hover:bg-blue-100" : "",
        node.isReviewed ? "opacity-50" : ""
      )}
      style={{ paddingLeft: `${level * 1.25 + 1.5}rem` }}
    >
      {node.isReviewed ? (
        <Tooltip>
          <TooltipTrigger>
            <CheckCircle2 className="h-4 w-4 text-gray-400 flex-shrink-0" />
          </TooltipTrigger>
          <TooltipContent>Already reviewed</TooltipContent>
        </Tooltip>
      ) : (
        <Checkbox
          checked={!!node.isSelected}
          onCheckedChange={(checked) => {
            onSelectFile(node);
          }}
          id={node.id || node.name}
          className="flex-shrink-0"
          disabled={node.isReviewed}
        />
      )}
      <FileIcon className="h-[16px] w-[16px] text-gray-500 ml-2 flex-shrink-0" />
      <label
        htmlFor={node.id || node.name}
        className={cn(
          "ml-2 cursor-pointer truncate",
          node.isReviewed && "cursor-not-allowed text-gray-500"
        )}
      >
        {node.name}
      </label>
    </div>
  );
};

type FileTreeProps = {
  files: FileIndex[];
  folderName: string;
  onSelectFile?: (file: FileIndex) => void;
};

export const FileTree: React.FC<FileTreeProps> = ({
  files,
  folderName,
  onSelectFile,
}) => {
  return (
    <div className="border rounded-lg p-4 max-w-lg bg-white shadow-sm">
      <h2 className="text-lg font-bold mb-4 text-gray-800">{folderName}</h2>
      {files.map((node) => (
        <FileTreeNode
          key={node.id || node.name}
          node={node}
          level={0}
          onSelectFile={(file) => onSelectFile?.(file)}
        />
      ))}
    </div>
  );
};
