import "./assets/globals.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RequiredAuthProvider, RedirectToLogin } from "@propelauth/react";

// Import page components
import { ServicesProviderWrapper } from "./components/ServicesProviderWrapper";
import { SearchPage } from "./pages/search/SearchView";
import { SettingsPage } from "./pages/settings/SettingsView";
import { Toaster } from "sonner";
import { TooltipProvider } from "./shadcn/components/tooltip";
import { DocChatView } from "./pages/doc-chat/DocChatView";
import { RegulatoryDocView } from "./pages/regulatory_docs/RegulatoryDocView";
import { AuditAnswerView } from "./pages/audit/AuditQuestionAnswerView";
import { AuditQuestionView } from "./pages/audit/AuditQuestionView";
import { AuditView } from "./pages/audit/AuditView";
import { InsightView } from "./pages/insight/InsightView";
import { InsightTableView } from "./pages/insight/InsightTableView";
import { DocViewerProvider } from "./contexts/DocViewerContext";
import { UserProvider } from "./contexts/UserContext";
import { PolicyRepositoryDocView } from "./pages/policy_repository/PolicyRepositoryDocView";
import { PolicyRepositoryOverviewView } from "./pages/policy_repository/PolicyRepositoryOverviewView";
import { GapAnalysisView } from "./pages/gap-analysis/GapAnalysisView";
import { GapAnalysisOverviewView } from "./pages/gap-analysis/GapAnalysisOverview";
import { ModalProvider } from "./contexts/ActiveModalContext";
import { RequirementProvider } from "./contexts/RequirementContext";
import { AuditResourceView } from "./pages/audit/AuditResourceView";
import { AuditUploadView } from "./pages/audit/AuditUploadView";
import { DashboardRouter } from "./pages/dashboard/DashboardRouter";
import { RegulatoryDocSummaryView } from "./pages/regulatory_docs/RegulatoryDocSummaryView";
import { CrosswalkView } from "./pages/DEMO_crosswalk/CrosswalkView";
import { ChatProvider } from "./contexts/ChatContext";
import { AuditAnswerReviewView } from "./pages/audit/AuditQuestionReviewView";
import { AuditToolView } from "./pages/auditTool/AuditToolView";
import AuditToolDashboard from "./pages/auditTool/AuditToolDashboard";
import { AuditToolProvider } from "./contexts/AuditToolContext";
import { AuditToolFactorAuditView } from "./pages/auditTool/AuditToolFactorAuditView";
import { AdminPage } from "./pages/admin/AdminView";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const container = document.getElementById("root");
const root = createRoot(container!);

export const App = () => {
  return (
    <ServicesProviderWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TooltipProvider delayDuration={0}>
          <BrowserRouter>
            <UserProvider>
              <DocViewerProvider>
                <ChatProvider>
                  <ModalProvider>
                    <AuditToolProvider>
                      <Routes>
                        <Route
                          path="/audit/:auditId/:auditResourceId/question/:questionId/review"
                          element={<AuditAnswerReviewView />}
                        />
                        <Route
                          path="/delegate-audit"
                          element={<AuditToolView />}
                        />
                        <Route
                          path="/delegate-audit/:auditToolId/factor/:factorId/audit"
                          element={<AuditToolFactorAuditView />}
                        />
                        <Route
                          path="/delegate-audit/:auditToolId"
                          element={<AuditToolDashboard />}
                        />
                        <Route
                          path="/delegate-audit/:auditToolId/element/:elementId"
                          element={<AuditToolDashboard />}
                        />
                        <Route
                          path="/audit/:auditId/:auditResourceId/question/:questionId"
                          element={<AuditAnswerView />}
                        />
                        <Route
                          path="/audit/:auditId/questionnaire/:auditResourceId"
                          element={<AuditQuestionView />}
                        />
                        <Route
                          path="/audit/:auditId/upload/:auditResourceId"
                          element={<AuditUploadView />}
                        />
                        <Route
                          path="/audit/:auditId/table/:auditResourceId"
                          element={<AuditUploadView />}
                        />
                        <Route
                          path="/audit/:auditId"
                          element={<AuditResourceView />}
                        />
                        <Route path="/audit" element={<AuditView />} />
                        <Route path="/insight" element={<InsightView />} />
                        <Route
                          path="/insight/:insightId"
                          element={<InsightTableView />}
                        />
                        <Route
                          path="/regulatory-doc"
                          element={<RegulatoryDocSummaryView />}
                        />
                        <Route
                          path="/regulatory-doc/overview/:regulatoryDocId"
                          element={
                            <RequirementProvider defaultActiveTab="overview">
                              <RegulatoryDocView />
                            </RequirementProvider>
                          }
                        />
                        <Route
                          path="/gap-analysis/:gapAnalysisId"
                          element={
                            <RequirementProvider defaultActiveTab="requirements">
                              <GapAnalysisOverviewView />
                            </RequirementProvider>
                          }
                        />
                        <Route
                          path="/gap-analysis"
                          element={<GapAnalysisView />}
                        />
                        <Route
                          path="/dashboard"
                          element={<DashboardRouter />}
                        />
                        <Route path="/crosswalk" element={<CrosswalkView />} />
                        <Route
                          path="/policy-repo/doc-view/:docId"
                          element={<PolicyRepositoryDocView />}
                        />
                        <Route
                          path="/policy-repo"
                          element={<PolicyRepositoryOverviewView />}
                        />
                        <Route path="/doc-chat" element={<DocChatView />} />
                        <Route path="/search" element={<SearchPage />} />
                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route
                          path="/"
                          element={<Navigate replace to="/search" />}
                        />
                      </Routes>
                    </AuditToolProvider>
                  </ModalProvider>
                </ChatProvider>
              </DocViewerProvider>
            </UserProvider>
            <Toaster richColors />
          </BrowserRouter>
        </TooltipProvider>
      </LocalizationProvider>
    </ServicesProviderWrapper>
  );
};

const authUrl = import.meta.env.VITE_AUTH_URL;

root.render(
  <RequiredAuthProvider
    // authUrl={process.env.AUTH_URL}
    authUrl={authUrl}
    // displayWhileLoading={<Loading />}
    displayIfLoggedOut={
      <RedirectToLogin postLoginRedirectUrl={window.location.href} />
    }
  >
    <App />
  </RequiredAuthProvider>
);
