import { useAuthInfo } from "@propelauth/react";
import {
  InfoCircledIcon,
  Pencil1Icon,
  PlusIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { SparkleIcon } from "lucide-react";
import { useContext, useState } from "react";
import { toast } from "sonner";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { UserContext } from "../../contexts/UserContext";
import { Badge } from "../../shadcn/components/badge";
import { Button } from "../../shadcn/components/button";
import { Separator } from "../../shadcn/components/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Citation, Department, DocType, Requirement } from "../../types";
import {
  addCitation,
  debounce,
  generateCitations,
  getRelevantDocs,
  saveAssignees,
  updateRequirementReferenceDocTypes,
} from "../../utils/apiCalls";
import { CitationDialog } from "../Citation/CitationViewDialog";
import { MultiSelectControl } from "../MultiSelectControl";

const SelectDataSources = (props: {
  docId: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  requirement: Requirement;
  docTypeAttr: "reference_doc_types" | "impacted_doc_types";
  allowedDocTypes: DocType[];
}) => {
  const authInfo = useAuthInfo();

  const updateRefDocTypes = debounce((docTypeIds: string[]) => {
    updateRequirementReferenceDocTypes(
      props.docId,
      props.requirement.id!,
      docTypeIds,
      authInfo.accessToken ?? null
    );
  }, 1000);

  return (
    <MultiSelectControl
      title="Data Sources"
      items={props.allowedDocTypes.map((docType) => ({
        id: docType.id,
        name: docType.name,
      }))}
      selectedItems={
        props.requirement[props.docTypeAttr]?.map((docTypeId) => ({
          id: docTypeId,
          name:
            props.allowedDocTypes.find((docType) => docType.id === docTypeId)
              ?.name ?? "",
        })) ?? []
      }
      clearSelectedItems={() => {
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              return {
                ...requirement,
                [props.docTypeAttr]: [],
              };
            }
            return requirement;
          });
        });
        updateRefDocTypes([]);
      }}
      selectItem={(item, isSelected) => {
        let newDocTypes: string[] = [];
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              newDocTypes = requirement[props.docTypeAttr] ?? [];
              if (isSelected) {
                newDocTypes = [...newDocTypes, item.id];
              } else {
                newDocTypes = newDocTypes.filter((n) => n !== item.id);
              }
              return {
                ...requirement,
                [props.docTypeAttr]: newDocTypes,
              };
            }
            return requirement;
          });
        });
        updateRefDocTypes(newDocTypes);
      }}
      selectAll={() => {
        let newDocTypes: string[] = props.allowedDocTypes.map(
          (docType) => docType.id
        );
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              return {
                ...requirement,
                [props.docTypeAttr]: newDocTypes,
              };
            }
            return requirement;
          });
        });
        updateRefDocTypes(newDocTypes);
      }}
      selectItemOnly={(item) => {
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              return {
                ...requirement,
                [props.docTypeAttr]: [item.id],
              };
            }
            return requirement;
          });
        });
        updateRefDocTypes([item.id]);
      }}
    />
  );
};

const RequirementDocumentsView = (props: {
  docId: string;
  requirement: Requirement;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  actionItemIds: string[];
  uniqueDepartments: Department[];
  allowedDocTypes: DocType[];
  title: string;
  viewDescription: string;
  citationType: "impacted-document" | "reference-document";
  requirementAttr: "impacted_documents" | "reference_documents";
  actionItemAttr: "citations" | "reference_documents";
  docTypeAttr: "reference_doc_types" | "impacted_doc_types";
  enableDocTypeSelection?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const { setDocToView, setPageNumber, setCitations } =
    useContext(DocViewerContext);
  const [generateCitationLoading, setGenerateCitationLoading] =
    useState<boolean>(false);
  const allowedDocTypeIds =
    props.docTypeAttr === "reference_doc_types"
      ? props.requirement[props.docTypeAttr] ?? []
      : props.allowedDocTypes.map((docType) => docType.id);

  const uniqueCitations = [
    ...new Map(
      props.requirement[props.requirementAttr]
        .concat(
          props.requirement.action_items.flatMap(
            (action_item) => action_item[props.actionItemAttr]
          )
        )
        .filter(Boolean)
        .map((citation) => [citation.text, citation])
    ).values(),
  ];

  const citationsByDoc = uniqueCitations.reduce(
    (acc: { [key: string]: Citation[] }, citation) => {
      const docName = citation.doc_name || "Unknown Document";
      if (!acc[docName]) {
        acc[docName] = [];
      }
      acc[docName].push(citation);
      return acc;
    },
    {}
  );

  const onClickGenerateCitation = async () => {
    setGenerateCitationLoading(true);
    let generationOutput: {
      citations: Citation[];
      departments: Department[];
    } = {
      citations: [],
      departments: [],
    };
    try {
      for await (const output of generateCitations(
        `gap-analysis/requirement/${props.citationType}/citation-generate/${props.docId}/${props.requirement.id}`,
        allowedDocTypeIds,
        authInfo.accessToken ?? null
      )) {
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              const departmentsToAdd = output.departments.filter(
                (department) =>
                  !props.uniqueDepartments
                    .map((d) => d.id)
                    .includes(department.id)
              );
              // unique citations
              const uniqueRequirementCitations = [
                ...new Map(
                  output.citations
                    .concat(
                      props.requirement[props.requirementAttr].filter(
                        (citation) => citation.id !== null
                      )
                    )
                    .filter(Boolean)
                    .map((citation) => [citation.id, citation])
                ).values(),
              ];

              return {
                ...requirement,
                [props.requirementAttr]: uniqueRequirementCitations,
                assignees: [...props.uniqueDepartments, ...departmentsToAdd],
                action_items: requirement.action_items.map((action_item) => {
                  const uniqueActionItemCitations = [
                    ...new Map(
                      output.citations
                        .concat(
                          action_item[props.actionItemAttr].filter(
                            (citation) => citation.id !== null
                          )
                        )
                        .filter(Boolean)
                        .map((citation) => [citation.id, citation])
                    ).values(),
                  ];
                  return {
                    ...action_item,
                    [props.actionItemAttr]: uniqueActionItemCitations,
                    assignees: [
                      ...props.uniqueDepartments,
                      ...departmentsToAdd,
                    ],
                  };
                }),
              };
            }
            return requirement;
          });
        });
        generationOutput = output;
      }
      if (generationOutput.citations.length === 0) {
        toast.info("No suggestions at the moment");
      } else {
        if (props.actionItemIds.length > 0) {
          for (const citation of generationOutput.citations) {
            for (const actionItemId of props.actionItemIds) {
              await addCitation(
                `gap-analysis/action-item/${props.citationType}/citation/${props.docId}/${props.requirement.id}/${actionItemId}`,
                citation,
                authInfo.accessToken ?? ""
              );
              if (generationOutput.departments.length > 0) {
                await saveAssignees(
                  `gap-analysis/action-item/assignees/${props.docId}/${props.requirement.id}/${actionItemId}`,
                  generationOutput.departments,
                  authInfo.accessToken ?? ""
                );
              }
            }
          }
        }
      }
    } catch (error: any) {
      toast.error("Failed to generate citations");
    } finally {
      setGenerateCitationLoading(false);
    }
  };

  const fetchRelevantDocs = async () => {
    const response = await getRelevantDocs(
      props.docId,
      props.requirement.id!,
      allowedDocTypeIds,
      props.citationType,
      authInfo.accessToken ?? null
    );
    return response;
  };

  return (
    <div className="space-y-3 bg-gray-300 rounded-b-md">
      <Separator className="bg-gray-200" />
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-2">
          <div className="text-base font-semibold">{props.title}</div>
          <Tooltip>
            <TooltipTrigger>
              <InfoCircledIcon className="w-4 h-4" />
            </TooltipTrigger>
            <TooltipContent>{props.viewDescription}</TooltipContent>
          </Tooltip>
        </div>
        <div
          className="space-x-2 flex items-center"
          style={{ transform: "scale(0.90)", transformOrigin: "right" }}
        >
          {props.enableDocTypeSelection && (
            <SelectDataSources
              docId={props.docId}
              setRequirements={props.setRequirements}
              requirement={props.requirement}
              allowedDocTypes={props.allowedDocTypes}
              docTypeAttr={props.docTypeAttr}
            />
          )}
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="outline"
                size="sm"
                onClick={onClickGenerateCitation}
                disabled={
                  generateCitationLoading || allowedDocTypeIds.length === 0
                }
              >
                {generateCitationLoading ? (
                  <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
                ) : (
                  <SparkleIcon className="w-4 h-4 mr-2" />
                )}
                Suggest
              </Button>
            </TooltipTrigger>
            <TooltipContent>Suggest Impacted Documents</TooltipContent>
          </Tooltip>
          <CitationDialog
            buttonLabel={
              uniqueCitations.length === 0 ? (
                <>
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add
                </>
              ) : (
                <>
                  <Pencil1Icon className="w-4 h-4 mr-2" />
                  Edit
                </>
              )
            }
            question={props.requirement.text}
            labelText={props.title}
            headerChildren={
              <Tooltip>
                <TooltipTrigger>
                  <div className="text-left text-gray-500 text-sm">
                    {props.requirement.text.slice(0, 100)}
                    {props.requirement.text.length > 100 && "..."}
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[300px]">
                  {props.requirement.text}
                </TooltipContent>
              </Tooltip>
            }
            citations={uniqueCitations}
            hideAtlasWidget={true}
            onClose={() => {
              setDocToView({
                docId: props.docId,
              });
              setPageNumber(props.requirement.citations[0]?.page ?? 1);
              setCitations([
                {
                  id: props.requirement.citations[0].id ?? "",
                  match: props.requirement.citations[0].text ?? "",
                  exactMatch: false,
                  page: props.requirement.citations[0].page ?? 1,
                },
              ]);
            }}
            allowedDocTypeIds={allowedDocTypeIds}
            fetchRelevantDocs={fetchRelevantDocs}
            persistUrl={{
              prefix: `gap-analysis/requirement/${props.citationType}`,
              suffix: `${props.docId}/${props.requirement.id}`,
            }}
            onNewCitationSuccess={(newCitation, originalCitation) => {
              props.setRequirements((prev) => {
                return prev.map((requirement) => {
                  if (requirement.id === props.requirement.id) {
                    return {
                      ...requirement,
                      [props.requirementAttr]: [
                        ...requirement[props.requirementAttr].filter(
                          (citation) => citation.id !== originalCitation?.id
                        ),
                        newCitation,
                      ],
                      action_items: requirement.action_items.map(
                        (action_item) => {
                          return {
                            ...action_item,
                            [props.actionItemAttr]: [
                              ...action_item[props.actionItemAttr].filter(
                                (citation) =>
                                  citation.id !== originalCitation?.id
                              ),
                              newCitation,
                            ],
                          };
                        }
                      ),
                    };
                  }
                  return requirement;
                });
              });
            }}
            onDeleteCitationSuccess={(deletedCitation: Citation) => {
              props.setRequirements((prev) => {
                return prev.map((requirement) => {
                  if (requirement.id === props.requirement.id) {
                    return {
                      ...requirement,
                      [props.requirementAttr]: requirement[
                        props.requirementAttr
                      ].filter(
                        (citation) => citation.id !== deletedCitation.id
                      ),
                      action_items: requirement.action_items.map(
                        (action_item) => {
                          return {
                            ...action_item,
                            [props.actionItemAttr]: action_item[
                              props.actionItemAttr
                            ].filter(
                              (citation) => citation.id !== deletedCitation.id
                            ),
                          };
                        }
                      ),
                    };
                  }
                  return requirement;
                });
              });
            }}
          />
        </div>
      </div>
      <div
        className="flex flex-wrap pl-2 gap-2 transform scale-90"
        style={{ transformOrigin: "left" }}
      >
        {Object.keys(citationsByDoc).length > 0 && (
          <div className="flex flex-wrap gap-2">
            {Object.keys(citationsByDoc).map((docName) => (
              <Tooltip key={docName}>
                <TooltipTrigger>
                  <Badge className="text-sm bg-gray-400 flex items-center gap-1">
                    <div className="max-w-[100px] truncate text-ellipsis">
                      {docName}
                    </div>
                    <span className="bg-gray-600 text-white text-xs px-1.5 rounded">
                      {citationsByDoc[docName].length}
                    </span>
                  </Badge>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent className="max-w-[300px] max-h-[190px] overflow-hidden">
                    <div className="line-clamp-6">
                      {citationsByDoc[docName][0].formatted_text ||
                        citationsByDoc[docName][0].text}
                    </div>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const RequirementImpactedDocumentView = (props: {
  docId: string;
  requirement: Requirement;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  actionItemIds: string[];
  uniqueDepartments: Department[];
}) => {
  const { regDocGapDocTypes } = useContext(UserContext);
  return (
    <RequirementDocumentsView
      docId={props.docId}
      requirement={props.requirement}
      setRequirements={props.setRequirements}
      actionItemIds={props.actionItemIds}
      uniqueDepartments={props.uniqueDepartments}
      allowedDocTypes={regDocGapDocTypes}
      title="Impacted Documents"
      citationType="impacted-document"
      requirementAttr="impacted_documents"
      actionItemAttr="citations"
      docTypeAttr="impacted_doc_types"
      enableDocTypeSelection={false}
      viewDescription="Internal documents that are impacted by this requirement and can be potentially altered to address the requirement."
    />
  );
};
