import { useAuthInfo } from "@propelauth/react";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { ActionItemView } from "../../components/GapAnalysis/ActionItemView";
import {
  RequirementControlsHeader,
  RequirementView,
} from "../../components/GapAnalysis/RequirementView";
import { Layout } from "../../components/Layout";
import { LoadingView } from "../../components/Loading";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { RequirementContext } from "../../contexts/RequirementContext";
import { UserContext } from "../../contexts/UserContext";
import { Badge } from "../../shadcn/components/badge";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import { GapAnalysis } from "../../types";
import { getGapAnalysis } from "../../utils/apiCalls";

const PageHeader = (props: { gapAnalysis: GapAnalysis }) => {
  return (
    <div className="space-y-1 overflow-hidden whitespace-nowrap">
      <div className="text-2xl font-bold truncate text-ellipsis">
        {props.gapAnalysis.name}
      </div>
      <div className="text-sm text-gray-500">{props.gapAnalysis.doc_name}</div>
    </div>
  );
};

export const GapAnalysisOverviewView = () => {
  const { gapAnalysisId } = useParams<{
    gapAnalysisId: string;
  }>();
  const authInfo = useAuthInfo();
  const { regDocGapDocTypes } = useContext(UserContext);
  const {
    requirements,
    setRequirements,
    requirementsLoading,
    loadRequirements,
    activeTab,
    setActiveTab,
  } = useContext(RequirementContext);
  const { docToView, setDocToView, setCitations, setPageNumber } =
    useContext(DocViewerContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [gapAnalysis, setGapAnalysis] = useState<GapAnalysis | null>(null);

  useEffect(() => {
    const tab = searchParams.get("activeTab");
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    setSearchParams(
      (prev) => {
        prev.set("activeTab", activeTab);
        return prev;
      },
      {
        replace: true,
      }
    );
    setCitations([]);
    setPageNumber(1);
  }, [activeTab]);

  useEffect(() => {
    if (gapAnalysisId) {
      getGapAnalysis(gapAnalysisId, authInfo.accessToken ?? null).then(
        (analysis) => {
          if (analysis !== null) {
            setGapAnalysis(analysis);
            if (docToView?.docId !== analysis.doc_id) {
              setDocToView({
                docId: analysis.doc_id,
              });
              setCitations([]);
              setPageNumber(1);
            }
          } else {
            toast.error("Failed to load gap analysis");
          }
        }
      );
    }
  }, [gapAnalysisId]);

  useEffect(() => {
    if (gapAnalysis?.doc_id) {
      loadRequirements(gapAnalysis.doc_id);
    }
  }, [gapAnalysis?.doc_id]);

  return (
    <Layout
      pageHeader={gapAnalysis && <PageHeader gapAnalysis={gapAnalysis} />}
    >
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <div className="flex justify-between mb-4">
          <div className="flex items-center space-x-2">
            <TabsList className="bg-gray-200">
              <TabsTrigger className="h-8 px-6" value="requirements">
                Requirements{" "}
                {requirements.length > 0 && (
                  <Badge className="ml-2 bg-gray-600">
                    {requirements.length}
                  </Badge>
                )}
              </TabsTrigger>
              <TabsTrigger className="h-8 px-6" value="actions">
                Action Items{" "}
                {requirements.flatMap((requirement) => requirement.action_items)
                  .length > 0 && (
                  <Badge className="ml-2 bg-gray-600">
                    {
                      requirements.flatMap(
                        (requirement) => requirement.action_items
                      ).length
                    }
                  </Badge>
                )}
              </TabsTrigger>
            </TabsList>
          </div>
          <RequirementControlsHeader
            docId={gapAnalysis?.doc_id ?? ""}
            requirementView={activeTab === "requirements"}
          />
        </div>
        <TabsContent value="requirements">
          {gapAnalysis && (
            <RequirementView
              actionItemRoutePrefix={`/gap-analysis/${gapAnalysis.id}?activeTab=actions&actionItemId=`}
              docId={gapAnalysis.doc_id}
            />
          )}
        </TabsContent>
        <TabsContent value="actions">
          {gapAnalysis && !requirementsLoading && (
            <ActionItemView
              requirementItemRoutePrefix={`/gap-analysis/${gapAnalysis.id}?activeTab=requirements&requirementId=`}
              docId={gapAnalysis.doc_id}
              requirements={requirements}
              setRequirements={setRequirements}
              allowedDocTypeIds={regDocGapDocTypes.map((docType) => docType.id)}
            />
          )}
          {requirementsLoading && (
            <LoadingView customText="Loading requirements..." />
          )}
        </TabsContent>
      </Tabs>
    </Layout>
  );
};
