import { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { Badge } from "../shadcn/components/badge";
import { Button } from "../shadcn/components/button";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ReloadIcon,
  RotateCounterClockwiseIcon,
} from "@radix-ui/react-icons";
import { Input } from "../shadcn/components/input";
import { LoadingView } from "./Loading";
import { Citation, ExactMatches } from "../types";
import { DocViewerContext } from "../contexts/DocViewerContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/components/dropdown-menu";
import { MoreVertical, SearchIcon } from "lucide-react";
import { toast } from "sonner";
import { useAuthInfo } from "@propelauth/react";
import {
  createPdfUrl,
  downloadData,
  individualDocClick,
  searchIndividualDoc,
} from "../utils/apiCalls";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/components/accordion";
import { cn } from "../shadcn/lib/utils";
import React from "react";
import { PdfDocumentViewer } from "./PdfViewer";
import * as pdfjsLib from "pdfjs-dist";
import { PdfCitation } from "./PdfViewer/PdfHighlighter/types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/components/dialog";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../shadcn/components/resizable";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../shadcn/components/tabs";
import { ChatView } from "../pages/doc-chat/ChatView";
import { toggleAtlasWidget } from "../utils/cookies";
import { useChatContext } from "../contexts/ChatContext";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../shadcn/components/popover";
import { useModalContext } from "../contexts/ActiveModalContext";

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const ClickableCitation = (props: {
  queryId: string | null;
  citation: Citation;
}) => {
  const authInfo = useAuthInfo();
  const { setCitations, setPageNumber } = useContext(DocViewerContext);
  return (
    <div
      className="text-left gap-4 p-3 w-full rounded-md shadow-md cursor-pointer hover:bg-gray-100"
      key={props.citation.id}
      onClick={() => {
        setPageNumber(props.citation.page);
        if (props.queryId) {
          individualDocClick(
            props.queryId,
            "result",
            props.citation.id!,
            authInfo.accessToken ?? null
          );
        }
      }}
    >
      {props.citation.text.slice(0, 100)}...
    </div>
  );
};

const AdditionalViewerControls = (props: { hideAtlasWidget?: boolean }) => {
  const authInfo = useAuthInfo();
  const { docToView, pageNumber, citations } = useContext(DocViewerContext);

  const onLinkClick = async () => {
    let link = `${window.location.origin}/doc-chat?docId=${docToView?.docId}&page=${pageNumber}`;
    if (citations[0]?.match) {
      link += `&text=${citations[0].match}`;
    }
    await navigator.clipboard.writeText(link);
    toast.success("Copied to clipboard");
  };

  const onClickDownload = async () => {
    if (docToView) {
      const success = await downloadData(
        docToView.docId,
        authInfo.accessToken ?? null
      );
      if (!success) {
        toast.error("Error exporting data, please try again");
      }
    }
  };

  return (
    <DropdownMenu modal={true}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreVertical className="h-4 w-4" />
          <span className="sr-only">More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="max-w-[400px]">
        {/* <DropdownMenuItem onClick={() => setOwnersOpen(true)}>
          Owners
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onLinkClick}>
          Link to Section
        </DropdownMenuItem> */}
        <DropdownMenuItem onClick={onClickDownload}>
          Download Document
        </DropdownMenuItem>
        {/* {docMetadata?.regulatory_doc_id && (
          <DropdownMenuItem
            onClick={() =>
              navigate(
                `/regulatory-doc/overview/${docMetadata.regulatory_doc_id}`
              )
            }
          >
            Regulatory Doc Summary
          </DropdownMenuItem>
        )}
        {(docMetadata?.editable_doc_id || docMetadata?.approval_flow_id) && (
          <DropdownMenuItem
            onClick={() =>
              navigate(
                `/policy-repo/doc-view/${
                  docMetadata.editable_doc_id ?? docMetadata.id
                }`
              )
            }
          >
            Policy Repository
          </DropdownMenuItem>
        )} */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
type SearchResult = {
  contentMatch: Citation[];
  exactMatch: ExactMatches | null;
  query: string;
  queryId: string;
};
const DocSearchControls = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const { docToView, setPageNumber, setSearchResultCitations } =
    useContext(DocViewerContext);
  const { activeModalRef } = useModalContext();
  const authInfo = useAuthInfo();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult | null>(null);

  const createCitationsFromOutput = (output: SearchResult) => {
    setSearchResultCitations(() => {
      const newCitations: PdfCitation[] = [];

      // Add citations for search results
      output.contentMatch.forEach((result, index) => {
        newCitations.push({
          id: `content-match-result-${index}`,
          page: result.page,
          match: result.text,
          exactMatch: false,
          className: `bg-highlight-context`,
        });
      });

      // Add citations for exact matches
      if (output.exactMatch) {
        output.exactMatch.page_matches.forEach((match) => {
          Array(match.count)
            .fill(0)
            .forEach((_, index) =>
              newCitations.push({
                id: `searchTermCitation-${match.page}-${index}`,
                page: match.page,
                match: searchTerm,
                exactMatch: true,
                match_index: index + 1,
                className: `bg-highlight-exact`,
              })
            );
        });
      }

      return newCitations;
    });
  };
  const handleSearch = async (searchTerm: string) => {
    if (searchTerm === "" || searchLoading) {
      return;
    }
    setSearchLoading(true);
    setSearchResults(null);
    try {
      for await (const output of searchIndividualDoc(
        searchTerm,
        docToView?.docId ?? "",
        true,
        authInfo.accessToken ?? null
      )) {
        setSearchResults({
          contentMatch: output.search_results,
          exactMatch: output.pages_with_exact_match,
          query: searchTerm,
          queryId: output.query_id,
        });
      }
    } catch (error: any) {
      console.error("There was an error running the search", error);
      toast.error("Unable to run search");
    }
    setSearchLoading(false);
  };
  useEffect(() => {
    if (searchResults) {
      createCitationsFromOutput(searchResults);
    }
  }, [searchResults, isPopoverOpen]);
  useEffect(() => {
    setSearchResultCitations([]);
  }, []);
  return (
    <div className="flex items-center space-x-2">
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            variant={`${searchResults ? "default" : "outline"}`}
            size="default"
          >
            Search
            <SearchIcon className="w-4 h-4 ml-2" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="p-4 w-[35rem] bg-white bg-opacity-[0.9] backdrop-blur"
          container={activeModalRef?.current}
        >
          <div className="flex flex-row items-center space-x-2 mb-[6px]">
            <div className="flex-grow w-full pr-2">
              <Input
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(searchTerm);
                  }
                }}
              />
            </div>
            <div className="flex flex-row space-x-2">
              <Button
                variant="default"
                size="default"
                disabled={searchLoading || searchTerm === ""}
                onClick={() => handleSearch(searchTerm)}
              >
                <SearchIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
          {searchLoading && (
            <LoadingView customText={`Searching document...`} />
          )}
          <div className="space-y-2 overflow-y-scroll max-h-[300px]">
            {searchResults && searchResults.query && (
              <div className="text-sm text-gray-500">
                Search results for:{" "}
                <span className="font-semibold">"{searchResults.query}"</span>
              </div>
            )}
            {searchResults && searchResults.contentMatch.length > 0 && (
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem key="content-match" value="content-match">
                  <AccordionTrigger>
                    <div className="flex space-x-4">
                      <span>Content Match</span>
                      <Badge className="text-xs bg-gray-400">
                        {searchResults.contentMatch.length}
                      </Badge>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    {searchResults.contentMatch.map((result, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="cursor-pointer hover:bg-gray-100 rounded-md p-2 mb-2"
                          onClick={() => setPageNumber(result.page)}
                        >
                          <span className="text-xs text-gray-500 inline-block">
                            page {result.page}
                          </span>
                          <span className="truncate mr-2 block">
                            {result.text}
                          </span>
                        </div>
                        {index < searchResults.contentMatch.length - 1 && (
                          <hr className="my-2" />
                        )}
                      </React.Fragment>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
            {searchResults && searchResults.exactMatch && (
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem key="exact-match" value="exact-match">
                  <AccordionTrigger>
                    <div className="flex space-x-4">
                      <span>Exact Match</span>
                      <Badge className="text-xs bg-gray-400">
                        {searchResults.exactMatch.page_matches.length}{" "}
                      </Badge>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    {searchResults.exactMatch.page_matches.map(
                      (match, index) => (
                        <React.Fragment key={match.page}>
                          <div
                            className="cursor-pointer hover:bg-gray-100 rounded-md p-2 mb-2"
                            onClick={() => {
                              setPageNumber(match.page);
                            }}
                          >
                            <span className="inline-block mr-2">
                              page {match.page}
                            </span>
                            <Badge className="text-xs bg-gray-400">
                              {match.count}
                            </Badge>
                          </div>
                          {index <
                            (searchResults.exactMatch?.page_matches.length ??
                              0) -
                              1 && <hr className="my-2" />}
                        </React.Fragment>
                      )
                    )}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        </PopoverContent>
      </Popover>
      {searchResults && searchResults.query && (
        <Button
          variant="destructive"
          size="default"
          onClick={() => {
            setSearchTerm("");
            setSearchResultCitations([]);
            setSearchResults(null);
          }}
        >
          Clear
        </Button>
      )}
    </div>
  );
};

const NumPagesControls = () => {
  const { pageNumber, numPages, setPageNumber } = useContext(DocViewerContext);

  return (
    <div className="flex items-center space-x-1">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber((prevPage) => prevPage - 1)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="sr-only">Previous Page</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Previous Page</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={pageNumber >= numPages!}
            onClick={() => setPageNumber((prevPage) => prevPage + 1)}
          >
            <ArrowRightIcon className="w-4 h-4" />
            <span className="sr-only">Next Page</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Next Page</TooltipContent>
      </Tooltip>
      <div className="flex items-center space-x-1">
        <Input
          type="number"
          min="1"
          max={numPages!}
          value={pageNumber}
          onChange={(e) => setPageNumber(Number(e.target.value))}
          className="w-18 text-center"
          aria-label="Go to page"
          disabled={numPages === null}
          onFocus={(e) => e.target.select()}
        />
        <div className="w-12">/ {numPages}</div>
      </div>
    </div>
  );
};

const DocViewerDialog = (props: { hideAtlasWidget?: boolean }) => {
  const { docToView, docMetadata } = useContext(DocViewerContext);
  const {
    setChatMessages,
    setSelectedResources,
    setActiveResourceId,
    setChatId,
  } = useChatContext();
  const [dOpen, setDOpen] = useState<boolean>(false);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  return (
    <Dialog
      open={dOpen}
      onOpenChange={(dOpen) => {
        setDOpen(dOpen);
        if (props.hideAtlasWidget) {
          toggleAtlasWidget(dOpen);
        }
        if (dOpen && docToView && docMetadata) {
          setSelectedResources([
            {
              id: docToView.docId,
              name: docMetadata.name,
              type: "document",
              match_type: "name",
              citation: null,
              regulatory_doc_id: null,
              count: null,
              doc_type_name: null,
            },
          ]);
          setActiveResourceId(docToView.docId);
        } else if (!dOpen) {
          setSelectedResources([]);
          setActiveResourceId(null);
          setChatMessages(null);
          setChatId(null);
        }
      }}
    >
      <DialogTrigger asChild>
        <div className="cursor-pointer max-w-[100%] text-md font-semibold truncate text-ellipsis overflow-hidden whitespace-nowrap hover:underline hover:text-blue-500">
          {decodeURI(docMetadata?.name ?? "")}
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-[90%] h-[90%]">
        <DialogTitle>{docMetadata?.name}</DialogTitle>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel>
            <div className="mr-4 relative">
              <ChatView />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel>
            <div className="space-y-4 mt-2 pl-2">
              <BaseDocControls hideAtlasWidget={props.hideAtlasWidget} />
              <div
                ref={scrollBoxRef}
                className="overflow-y-auto h-[calc(100vh-275px)]"
              >
                {docToView && <DocViewer />}
              </div>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </DialogContent>
    </Dialog>
  );
};

const BaseDocControls = (props: {
  hideAtlasWidget?: boolean;
  children?: React.ReactNode;
}) => {
  const { docToView, setRotation, numPages } = useContext(DocViewerContext);
  return (
    <div className="flex justify-between max-w-[100%] items-center space-x-2 overflow-x-auto">
      <div className="flex items-center space-x-2">
        {numPages && (
          <>
            <NumPagesControls />
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setRotation((prev) => prev - 90)}
                >
                  <RotateCounterClockwiseIcon className="w-4 h-4" />
                  <span className="sr-only">Rotate Left</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Rotate Left</TooltipContent>
            </Tooltip>
            <DocSearchControls />
          </>
        )}
        {props.children}
      </div>
      {docToView && (
        <AdditionalViewerControls hideAtlasWidget={props.hideAtlasWidget} />
      )}
    </div>
  );
};

export const BaseDocViewerControls = (props: {
  hideAtlasWidget?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <div className="space-y-2 w-full">
      <DocViewerDialog hideAtlasWidget={props.hideAtlasWidget} />
      <BaseDocControls hideAtlasWidget={props.hideAtlasWidget}>
        {props.children}
      </BaseDocControls>
    </div>
  );
};

type DocViewerProps = {
  onCitationsUpdate?: (citation: PdfCitation) => void;
  parentModalRef?: React.RefObject<HTMLDivElement>;
};
export const DocViewer: React.FC<DocViewerProps> = ({
  onCitationsUpdate,
  parentModalRef,
}) => {
  const {
    docToView,
    pageNumber,
    setPageNumber,
    setNumPages,
    citations,
    searchResultCitations,
    setCitations,
  } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();

  const fileUrl = useMemo(() => {
    return createPdfUrl(docToView?.docId ?? "", docToView?.historyId ?? "");
  }, [docToView?.docId, docToView?.historyId]);

  const fileOptions = useMemo(() => {
    return {
      httpHeaders: {
        Authorization: `Bearer ${authInfo.accessToken}`,
      },
    };
  }, [authInfo.isLoggedIn]);

  const defaultSetCitations = (citationText: PdfCitation) => {
    setCitations((prevCitations) => {
      const rangeCitationIndex = prevCitations.findIndex(
        (cit) => cit.id === citationText.id
      );
      if (rangeCitationIndex === -1) {
        return [citationText, ...prevCitations];
      } else {
        prevCitations[rangeCitationIndex] = citationText;
        return [...prevCitations];
      }
    });
  };

  const handleCitationUpdate = (citation: PdfCitation) => {
    if (onCitationsUpdate) {
      onCitationsUpdate(citation);
    } else {
      defaultSetCitations(citation);
    }
  };

  return (
    <PdfDocumentViewer
      url={fileUrl}
      fileOptions={fileOptions}
      documentLoadingContnet={<LoadingView />}
      pageLoadingContent={<LoadingView customText={``} />}
      pageNumber={pageNumber}
      citations={[...citations, ...searchResultCitations]}
      onUpdateCitation={handleCitationUpdate}
      onUpdatePageNumber={(pageNumber) => {
        setPageNumber(pageNumber);
      }}
      onUpdateNumPages={(numPages) => {
        setNumPages(numPages);
      }}
      parentModalRef={parentModalRef}
    />
  );
};

export const DocViewerCitation = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    docId: string;
    hideAtlasWidget?: boolean;
    children?: React.ReactNode;
    onCitationsUpdate?: (citations: PdfCitation) => void;
    parentModalRef?: React.RefObject<HTMLDivElement>;
  }
>(
  (
    {
      className,
      docId,
      hideAtlasWidget,
      children,
      onCitationsUpdate,
      parentModalRef,
    },
    ref
  ) => {
    const { docToView, setDocToView } = useContext(DocViewerContext);
    const scrollBoxRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (docId && (docToView?.docId !== docId || docToView === null)) {
        setDocToView({
          docId: docId,
        });
      }
    }, [docId]);

    return (
      <div className="space-y-4 mt-2 pl-2">
        <BaseDocViewerControls hideAtlasWidget={hideAtlasWidget}>
          {children}
        </BaseDocViewerControls>
        <div ref={scrollBoxRef} className={cn("overflow-y-auto", className)}>
          {docToView && (
            <DocViewer
              onCitationsUpdate={onCitationsUpdate}
              parentModalRef={parentModalRef}
            />
          )}
        </div>
      </div>
    );
  }
);
