import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  auditFactorAssessment,
  auditFactorAssessmentStatusLabelClasses,
  AuditTool,
  AuditToolElementReview,
  AuditToolRelatedDoc,
  CustomFactor,
  Factor,
} from "../../types";
import {
  addCustomFactor,
  addFactors,
  generateAuditToolAssessment,
  generateAuditToolElementReview,
  getAuditTool,
  getAuditToolElementReview,
  getAuditToolSharepointFolderInfo,
  getUploadedDocs,
  loadAuditToolDocs,
  searchFactors,
  updateAuditToolElementReview,
} from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { Layout } from "../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shadcn/components/breadcrumb";
import { Button } from "../../shadcn/components/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import {
  AssessmentProgressBar,
  FactorDashboardDisplayTwo,
  FactorTable,
} from "./FactorDashboardDisplayTwo";
import { FactorTableDisplay } from "./unused/FactorTableDisplay";
import {
  ResizableHandle,
  ResizablePanel,
} from "../../shadcn/components/resizable";
import { ResizablePanelGroup } from "../../shadcn/components/resizable";
import { toast } from "sonner";
import { FunctionalAreaSelect } from "./components";
import { Input } from "../../shadcn/components/input";
import { Textarea } from "../../shadcn/components/textarea";
import { StatusSelector } from "../../components/StatusSelector";
import { AutoSavingTextArea } from "../../components/AutoSavingTextArea";
import { TimeAgo } from "../../utils/format";
import { UserContext } from "../../contexts/UserContext";
import { ArrowLeft, Copy, Pencil, Sparkles } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { ElementReview } from "./ElementReview";
import { FactorTreeDisplay } from "./FactorTreeDisplay";
export const AuditToolBreadcrumbs = ({
  auditTool,
  factor,
}: {
  auditTool: AuditTool | null;
  factor?: Factor;
}) => {
  const [searchParams] = useSearchParams();
  const fa = searchParams.get("fa");
  const std = searchParams.get("std");
  const el = searchParams.get("el");
  return (
    <div className="flex flex-row items-center gap-2">
      {factor && (
        <div className="flex justify-start">
          <BreadcrumbLink
            href={`/delegate-audit/${auditTool?.id ?? ""}?fa=${fa ?? factor?.functional_area}&std=${std ?? factor?.standard_index ?? ""}&el=${el ?? factor?.element_index ?? ""}`}
          >
            <Button
              variant="outline"
              className="px-4 py-2 hover:bg-gray-100 transition-colors"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back
            </Button>
          </BreadcrumbLink>
        </div>
      )}
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={`/delegate-audit`}>
              Delegate Audit
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={`/delegate-audit/${auditTool?.id ?? ""}`}>
              {auditTool?.name ?? ""}
            </BreadcrumbLink>
          </BreadcrumbItem>
          {(el || factor?.element_index) && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={`/delegate-audit/${auditTool?.id ?? ""}?fa=${fa ?? factor?.functional_area}&std=${std ?? factor?.standard_index ?? ""}&el=${el ?? factor?.element_index ?? ""}`}
                >
                  {el ?? factor?.element_index}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
          {factor && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={`/delegate-audit/${auditTool?.id ?? ""}/factor/${factor.id}`}
                >
                  {factor.factor_title}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
        </BreadcrumbList>
      </Breadcrumb>
    </div>
  );
};

const CustomFactorDialog = ({
  onAddFactor,
}: {
  onAddFactor: (factors: {
    description: string;
    reference: string;
  }) => Promise<void>;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [factorInfo, setFactorInfo] = useState<{
    description: string;
    reference: string;
  }>({
    description: "",
    reference: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onAddFactor({ ...factorInfo });
      setIsDialogOpen(false);
      toast.success("Custom factor added successfully");
    } catch (error) {
      toast.error("Error adding custom factor");
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Custom Factor</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Custom Factor</DialogTitle>
          <DialogDescription>
            Create a custom factor for your audit
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="description">Description</label>
            <Textarea
              id="description"
              value={factorInfo.description}
              onChange={(e) =>
                setFactorInfo((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              required
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="reference">Reference</label>
            <Input
              id="reference"
              value={factorInfo.reference}
              onChange={(e) =>
                setFactorInfo((prev) => ({
                  ...prev,
                  reference: e.target.value,
                }))
              }
            />
          </div>
          <DialogFooter>
            <Button type="submit">Add Factor</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
const AddFactorsDialog = ({
  onAddFactors,
  presetFunctionalArea,
}: {
  onAddFactors: (factors: Factor[]) => Promise<void>;
  presetFunctionalArea?: string;
}) => {
  const authInfo = useAuthInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Factor[]>([]);
  const [functionalArea, setFunctionalArea] = useState(
    presetFunctionalArea ?? ""
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (isDialogOpen) {
      setSearchResults([]);
      setFunctionalArea("");
      if (presetFunctionalArea) {
        setFunctionalArea(presetFunctionalArea);
        handleSubmitFunctionalArea(presetFunctionalArea);
      }
    }
  }, [isDialogOpen]);

  const handleAddFactors = async (factors: Factor[]) => {
    try {
      setIsLoading(true);
      await onAddFactors(factors);
      toast.success("Factors added successfully");
    } catch (error) {
      toast.error("Error adding factors");
    } finally {
      setIsLoading(false);
      setIsDialogOpen(false);
    }
  };
  const handleSubmitFunctionalArea = async (functionalArea: string) => {
    setIsLoading(true);
    const results = await searchFactors(
      { functional_area: functionalArea },
      authInfo.accessToken ?? null
    );
    console.log(results);
    setSearchResults(results);
    setIsLoading(false);
  };
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Factors</Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90vw] w-[900px] max-h-[90vh] h-[90vh] overflow-y-auto flex flex-col">
        <DialogHeader>Add Factors</DialogHeader>
        <div className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="functionalArea">Functional Area</label>
            <FunctionalAreaSelect
              functionalArea={functionalArea}
              handleChangeFunctionalArea={(functionalArea) => {
                setFunctionalArea(functionalArea);
                handleSubmitFunctionalArea(functionalArea);
              }}
            />
          </div>
        </div>
        {isLoading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            <span className="ml-3">Loading factors...</span>
          </div>
        )}
        {searchResults.length > 0 && !isLoading && (
          <div className="overflow-y-auto flex-1">
            <FactorTreeDisplay
              factors={searchResults}
              onSubmit={handleAddFactors}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
export const SharepointUrlPopover = () => {
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const authInfo = useAuthInfo();
  const [sharepointUrl, setSharepointUrl] = useState<string | null>(null);
  const handleGetSharepointUrl = async () => {
    if (!auditToolId) {
      toast.error("No audit tool selected");
      return;
    }
    try {
      const response = await getAuditToolSharepointFolderInfo(
        auditToolId,
        authInfo.accessToken ?? null
      );
      const url = response.webUrl;
      setSharepointUrl(url);
    } catch (error) {
      toast.error("Error getting sharepoint url");
    }
  };
  useEffect(() => {
    handleGetSharepointUrl();
  }, [auditToolId]);
  useEffect(() => {
    handleGetSharepointUrl();
  }, []);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">Upload Documents</Button>
      </PopoverTrigger>
      <PopoverContent className="flex items-center gap-2 p-3 w-[400px]">
        <a
          href={sharepointUrl ?? "#"}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline truncate flex-1"
        >
          {sharepointUrl ?? "Loading..."}
        </a>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                if (sharepointUrl) {
                  navigator.clipboard.writeText(sharepointUrl);
                  toast.success("Copied to clipboard!");
                }
              }}
              className="h-8 w-8 flex-shrink-0 hover:bg-gray-100"
            >
              <Copy className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Copy to clipboard</TooltipContent>
        </Tooltip>
      </PopoverContent>
    </Popover>
  );
};
const AuditToolDashboard = () => {
  const { auditToolId } = useParams<{
    auditToolId: string;
  }>();
  const [searchParams] = useSearchParams();
  const fa = searchParams.get("fa");
  const std = searchParams.get("std");
  const el = searchParams.get("el");
  const [factorDisplayFactors, setFactorDisplayFactors] = useState<Factor[]>(
    []
  );
  const [isDocsLoading, setIsDocsLoading] = useState(false);
  const [elementReview, setElementReview] =
    useState<AuditToolElementReview | null>(null);
  const authInfo = useAuthInfo();
  const [isGeneratingElementReview, setIsGeneratingElementReview] =
    useState(false);
  const { simpleUsers } = useContext(UserContext);
  const { auditTool, setAuditTool } = useContext(AuditToolContext);
  const handleAddFactors = async (factors: Factor[]) => {
    if (!auditTool) {
      return;
    }
    const updatedFactors = await addFactors(
      auditTool.id,
      factors,
      authInfo.accessToken ?? null
    );
    setAuditTool((prevAuditTool) => {
      if (!prevAuditTool) return null;
      return {
        ...prevAuditTool,
        factors: updatedFactors,
      };
    });
  };
  const handleAddCustomFactor = async ({
    description,
    reference,
  }: {
    description: string;
    reference: string;
  }) => {
    if (!auditTool) {
      return;
    }
    const updatedFactors = await addCustomFactor(
      auditTool.id,
      { functional_area: auditTool?.scope ?? "", description, reference },
      authInfo.accessToken ?? null
    );
    setAuditTool((prevAuditTool) => {
      if (!prevAuditTool) return null;
      return {
        ...prevAuditTool,
        factors: updatedFactors,
      };
    });
  };
  const handleGenerateAuditToolAssessment = async () => {
    if (!auditTool) {
      return;
    }

    try {
      const { filename, blob } = await generateAuditToolAssessment(
        auditTool.id,
        authInfo.accessToken ?? null
      );
      // Create download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Assessment generated and downloaded successfully");
    } catch (error) {
      console.error("Error generating assessment:", error);
      toast.error("Failed to generate assessment");
    }
  };
  const handleGenerateElementReview = async () => {
    if (!auditTool || !std || !el) {
      return;
    }
    try {
      setIsGeneratingElementReview(true);
      const review = await generateAuditToolElementReview(
        auditTool.id,
        std,
        el,
        authInfo.accessToken ?? null
      );
      setElementReview(review);
    } catch (error) {
      toast.error("Error generating element review");
    } finally {
      setIsGeneratingElementReview(false);
    }
  };
  const handleUpdateElementReview = async (
    elementReview: AuditToolElementReview
  ) => {
    console.log("updating element review", elementReview);
    try {
      const updatedElementReview = await updateAuditToolElementReview(
        auditTool?.id ?? "",
        elementReview,
        authInfo.accessToken ?? null
      );
      setElementReview(updatedElementReview);
      toast.success("Element review updated successfully");
    } catch (error) {
      toast.error("Error updating element review");
    }
  };
  const fetchAuditTool = async () => {
    if (auditToolId) {
      const auditTool = await getAuditTool(
        auditToolId,
        authInfo.accessToken ?? null
      );
      if (auditTool) {
        setAuditTool(auditTool);
      }
    }
  };
  const fetchElementReview = async () => {
    if (!auditTool || !std || !el) {
      console.log("No audit tool or standard or element");
      return;
    }
    const review = await getAuditToolElementReview(
      auditTool.id,
      std,
      el,
      authInfo.accessToken ?? null
    );
    if (!review) {
      return;
    }
    setElementReview(review);
  };
  useEffect(() => {
    if (fa && std && el) {
      const factors = auditTool?.factors.filter(
        (factor) =>
          factor.functional_area === fa &&
          factor.standard_index === std &&
          factor.element_index === el
      );
      setFactorDisplayFactors(factors ?? []);
      fetchElementReview();
    }
  }, [fa, std, el, auditTool]);
  useEffect(() => {
    fetchAuditTool();
  }, [auditToolId]);
  useEffect(() => {
    fetchAuditTool();
  }, []);
  return (
    <Layout pageName={auditTool ? auditTool.name : "Audit Tool Dashboard"}>
      <div className="flex flex-col max-h-[calc(100vh-80px)] p-4 overflow-hidden">
        <div className="flex flex-row justify-between items-center mb-4">
          <AuditToolBreadcrumbs auditTool={auditTool} />
        </div>
        {!(fa && std && el) && (
          <div className="mb-4">
            <div className="toolbar bg-gray-200 p-2 pl-4 pr-4 rounded flex gap-2">
              {/* Toolbar content goes here */}
              <AddFactorsDialog
                onAddFactors={handleAddFactors}
                presetFunctionalArea={auditTool?.scope ?? undefined}
              />
              <CustomFactorDialog onAddFactor={handleAddCustomFactor} />
              <SharepointUrlPopover />
              <div className="flex-1"></div>
              <Tooltip>
                <TooltipTrigger>
                  <Button onClick={handleGenerateAuditToolAssessment}>
                    Export Audit
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Export Delegate Audit toExcel</TooltipContent>
              </Tooltip>
            </div>
          </div>
        )}
        <div className="card bg-white p-4 rounded shadow overflow-auto">
          {fa && std && el ? (
            <div className="flex flex-col gap-4">
              <h1 className="text-2xl font-bold">
                {std}-{el}: {factorDisplayFactors[0]?.element_title}
              </h1>
              <div className="mt-2">
                <AssessmentProgressBar factors={factorDisplayFactors} />
              </div>
              <ResizablePanelGroup direction="horizontal">
                <ResizablePanel>
                  <FactorTable
                    factors={factorDisplayFactors.sort((a, b) => {
                      const aMatch = a.factor_index.match(/\d+/);
                      const bMatch = b.factor_index.match(/\d+/);
                      const aNum = aMatch ? parseInt(aMatch[0]) : 0;
                      const bNum = bMatch ? parseInt(bMatch[0]) : 0;
                      return aNum - bNum;
                    })}
                  />
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel>
                  <ElementReview
                    auditToolId={auditTool?.id ?? ""}
                    fa={fa}
                    std={std}
                    el={el}
                    isFactorView={false}
                  />
                </ResizablePanel>
              </ResizablePanelGroup>
            </div>
          ) : (
            <FactorDashboardDisplayTwo />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AuditToolDashboard;
