import React, { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { Sparkles } from "lucide-react";
import { useAuthInfo } from "@propelauth/react";

import {
  AuditFactorAssessment,
  AuditToolElementReview,
  auditFactorAssessment,
  auditFactorAssessmentStatusLabelClasses,
} from "../../types";
import {
  generateAuditToolElementReview,
  getAuditToolElementReview,
  updateAuditToolElementReview,
} from "../../utils/apiCalls";

import { UserContext } from "../../contexts/UserContext";
import { TimeAgo } from "../../utils/format";
import { AutoSavingTextArea } from "../../components/AutoSavingTextArea";
import { StatusSelector } from "../../components/StatusSelector";
import { Button } from "../../shadcn/components/button";

type ElementReviewProps = {
  /** The ID of the AuditTool resource, e.g. from `useParams().auditToolId` */
  auditToolId: string;
  /** The functional area, standard, and element indexes for which we want to fetch the review */
  fa: string;
  std: string;
  el: string;
  isFactorView: boolean;
};

export const ElementReview = ({
  auditToolId,
  fa,
  std,
  el,
  isFactorView,
}: ElementReviewProps) => {
  const [elementReview, setElementReview] =
    useState<AuditToolElementReview | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const authInfo = useAuthInfo();
  const { simpleUsers } = useContext(UserContext);

  const fetchElementReview = async () => {
    if (!auditToolId || !std || !el) return;
    try {
      const review = await getAuditToolElementReview(
        auditToolId,
        std,
        el,
        authInfo.accessToken ?? null
      );
      setElementReview(review);
    } catch (error) {
      console.error("Error fetching element review:", error);
      toast.error("Error fetching element review");
    }
  };

  /**
   * Calls the server to generate an AuditToolElementReview (e.g. AI generation) for this fa/std/el.
   */
  const handleGenerateElementReview = async () => {
    if (!auditToolId || !std || !el) return;
    try {
      setIsGenerating(true);
      const review = await generateAuditToolElementReview(
        auditToolId,
        std,
        el,
        authInfo.accessToken ?? null
      );
      setElementReview(review);
    } catch (error) {
      console.error("Error generating element review:", error);
      toast.error("Error generating element review");
    } finally {
      setIsGenerating(false);
    }
  };

  /**
   * Updates an AuditToolElementReview after user input.
   */
  const handleUpdateElementReview = async (
    newReview: AuditToolElementReview
  ) => {
    try {
      const updatedElementReview = await updateAuditToolElementReview(
        auditToolId,
        newReview,
        authInfo.accessToken ?? null
      );
      setElementReview(updatedElementReview);
      toast.success("Element review updated successfully");
    } catch (error) {
      console.error("Error updating element review:", error);
      toast.error("Error updating element review");
    }
  };

  useEffect(() => {
    fetchElementReview();
  }, [auditToolId, fa, std, el]);

  if (!fa || !std || !el) return null;

  return (
    <div className="border-t p-2 pt-4 bg-gray-100 flex flex-col h-full">
      <h2 className="text-xl font-semibold mb-4">
        Element {isFactorView ? "Level Assessment" : "Assessment"}
        {isFactorView && <span className="text-gray-500 italic"> - {el}</span>}
      </h2>
      <div className="flex flex-col gap-4 flex-1">
        {/* 1. Assessment Status */}
        {!isFactorView && (
          <div>
            <label className="block text-sm font-medium mb-2">
              Assessment Status
            </label>
            <StatusSelector
              status={elementReview?.assessment ?? ""}
              handleStatusChange={async (value) => {
                if (!elementReview) return;
                await handleUpdateElementReview({
                  ...elementReview,
                  assessment: value,
                });
              }}
              options={Object.entries(auditFactorAssessment).map(
                ([key, value]) => ({
                  value: key,
                  label: value,
                  className:
                    auditFactorAssessmentStatusLabelClasses[
                      key as AuditFactorAssessment
                    ],
                })
              )}
              hideLabel={true}
            />
          </div>
        )}

        {/* 2. Narrative / Findings */}
        <div className="flex-1 flex flex-col">
          <label className="flex items-center justify-between block text-sm font-medium mb-2">
            {isFactorView ? "Notes" : "Findings and notes"}
            <Button
              variant="outline"
              onClick={handleGenerateElementReview}
              disabled={isGenerating}
            >
              Generate <Sparkles className="ml-2 w-4 h-4" />
            </Button>
          </label>
          <AutoSavingTextArea
            onSave={async (e) => {
              if (!elementReview || isGenerating) return;
              await handleUpdateElementReview({
                ...elementReview,
                narrative: e.target.value,
              });
            }}
            updatedText={
              elementReview ? (
                <div className="text-xs text-gray-500 text-center">
                  Last updated{" "}
                  <TimeAgo
                    timestamp={
                      elementReview.narrative_updated_at ??
                      new Date().toISOString()
                    }
                  />{" "}
                  by{" "}
                  {simpleUsers.find(
                    (user) => user.id === elementReview.narrative_updated_by
                  )?.first_name || "Unknown"}
                </div>
              ) : null
            }
            textareaProps={{
              value: elementReview?.narrative ?? "",
              onChange: (e) => {
                if (!elementReview) return;
                setElementReview({
                  ...elementReview,
                  narrative: e.target.value,
                });
              },
              rows: 5,
              placeholder: "Enter your findings and notes here...",
              className: "w-full flex-1 border rounded-md p-2",
            }}
            className="flex-1 flex flex-col pb-4"
          />
        </div>
      </div>
    </div>
  );
};
