import { AuditTool, Factor, FileIndex } from "../../types";

export type UniqueFunctionalArea = {
  functional_area: string;
  factors: Factor[];
};
export const getUniqueFunctionalAreas = (
  factors: Factor[]
): UniqueFunctionalArea[] => {
  const uniqueFunctionalAreas = Array.from(
    new Set(factors.map((factor) => factor.functional_area))
  );
  return uniqueFunctionalAreas.map((functional_area) => {
    return {
      functional_area: functional_area,
      factors: factors.filter(
        (factor) => factor.functional_area === functional_area
      ),
    };
  });
};

export type UniqueStandard = {
  standard_index: string;
  standard_title: string;
  factors: Factor[];
};
export const getUniqueStandards = (factors: Factor[]): UniqueStandard[] => {
  const uniqueStandards = Array.from(
    new Set(factors.map((factor) => factor.standard_index))
  );
  return uniqueStandards
    .map((standard_index) => {
      const standard = factors.find(
        (factor) => factor.standard_index === standard_index
      );
      return {
        standard_index: standard_index ?? "",
        standard_title: standard?.standard_title ?? "Untitled",
        factors: factors.filter(
          (factor) => factor.standard_index === standard_index
        ),
      };
    })
    .sort((a, b) => a.standard_index.localeCompare(b.standard_index));
};

export type UniqueElement = {
  element_index: string;
  element_title: string;
  element_explanation: string;
  factors: Factor[];
};
export const getUniqueElements = (factors: Factor[]): UniqueElement[] => {
  const uniqueElements = Array.from(
    new Set(factors.map((factor) => factor.element_index))
  );
  return uniqueElements
    .map((element_index) => {
      const element = factors.find(
        (factor) => factor.element_index === element_index
      );
      return {
        element_index: element_index ?? "",
        element_title: element?.element_title ?? "Untitled",
        element_explanation: element?.element_explanation ?? "",
        factors: factors.filter(
          (factor) => factor.element_index === element_index
        ),
      };
    })
    .sort((a, b) => a.element_index.localeCompare(b.element_index));
};

export const createFileIndexFromAuditTool = (
  auditTool: AuditTool,
  selectedRelatedDocIds: string[],
  reviewedDocIds: string[] = []
): FileIndex => {
  // Create the root folder
  const baseFolderName =
    auditTool.folder_path?.split("/").pop() || auditTool.name;

  const rootFolder: FileIndex = {
    type: "folder",
    name: baseFolderName,
    id: null,
    children: [],
    doc_type_name: "",
    regulatory_doc_id: null,
  };

  // Process each related document
  if (!auditTool.related_docs || auditTool.related_docs.length === 0) {
    return rootFolder;
  }
  auditTool.related_docs.forEach((doc) => {
    let currentLevel = rootFolder.children;
    const fullPath = doc.doc_url?.split("/").filter(Boolean) || [];
    const baseIndex = fullPath.indexOf(baseFolderName);
    const pathParts = baseIndex >= 0 ? fullPath.slice(baseIndex + 1) : [];
    // Create folder structure for each path segment except the last one (which is the file)
    for (let i = 0; i < pathParts.length - 1; i++) {
      const folderName = pathParts[i];
      let folder = currentLevel.find(
        (item) => item.type === "folder" && item.name === folderName
      );

      if (!folder) {
        folder = {
          type: "folder",
          name: folderName,
          id: null,
          children: [],
          doc_type_name: "",
          regulatory_doc_id: null,
        };
        currentLevel.push(folder);
      }

      currentLevel = folder.children;
    }
    // Add the file at the current level
    currentLevel.push({
      type: "file",
      name: doc.doc_name,
      id: doc.id,
      children: [],
      doc_type_name: doc.doc_type_name,
      regulatory_doc_id: doc.doc_id,
      isSelected: selectedRelatedDocIds.includes(doc.id),
      isReviewed: reviewedDocIds.includes(doc.id),
    });
  });

  return rootFolder;
};
