import { useAuthInfo } from "@propelauth/react";
import {
  CopyIcon,
  PlusIcon,
  ReloadIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { SparkleIcon } from "lucide-react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { Button } from "../../shadcn/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { cn } from "../../shadcn/lib/utils";
import { Citation, Department, SearchDocName, SimpleUser } from "../../types";
import {
  addCitation,
  deleteCitation,
  generateCitations,
} from "../../utils/apiCalls";
import { TimeAgo } from "../../utils/format";
import {
  ClearHighlightTooltipActionButton,
  HighlightTooltipActionButton,
} from "../HighlightTooltipActionButton";
import { HIGHLIGHTED_CITATION_ID } from "../PdfViewer";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";
import { NewCitation } from "./NewCitationDialog";

export const UserMetadata = (props: {
  user: SimpleUser;
  createdAt: string;
}) => {
  return (
    <div className="flex w-full items-center text-xs text-muted-foreground justify-between">
      <div>{`${props.user.first_name} ${props.user.last_name[0]}`}</div>
      <div>
        <TimeAgo timestamp={props.createdAt} />
      </div>
    </div>
  );
};

const DocCitationGroupView = (props: {
  docName: string;
  citations: Citation[];
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  removeCitation: (citations: Citation[]) => Promise<void>;
  active: boolean;
  onClick: () => void;
}) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const onClickDeleteAll = async () => {
    setDeleteLoading(true);
    await props.removeCitation(props.citations);
    setDeleteLoading(false);
  };

  return (
    <div
      className={cn(
        "p-5 rounded-md space-y-5 border border-gray-200",
        props.active
          ? "bg-gray-300"
          : "bg-white hover:bg-gray-200 cursor-pointer"
      )}
      onClick={props.onClick}
    >
      <div className="flex justify-between">
        <div className="font-semibold text-sm truncate text-ellipsis">
          {props.docName}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center space-x-1">
          {props.citations.map((citation, index) => (
            <CitationItem
              key={citation.id}
              citationNum={index + 1}
              citation={citation}
              active={citation.id === props.activeCitationId}
              onClick={(citationId) => {
                if (citationId !== props.activeCitationId) {
                  props.setActiveCitationId(citationId);
                } else {
                  props.setActiveCitationId(null);
                }
              }}
            />
          ))}
        </div>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="destructive"
              size="icon"
              onClick={onClickDeleteAll}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <ReloadIcon className="w-4 h-4 animate-spin" />
              ) : (
                <TrashIcon className="w-4 h-4" />
              )}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Delete all citations for this document
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
};

const CitationItem = (props: {
  citationNum: number;
  citation: Citation;
  active: boolean;
  onClick: (citationId: string) => void;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant={props.active ? "default" : "outline"}
          size="default"
          onClick={(event) => {
            event.stopPropagation();
            props.onClick(props.citation.id!);
          }}
        >
          Citation {props.citationNum}
        </Button>
      </TooltipTrigger>
      <TooltipContent className="max-w-[300px] max-h-[190px] overflow-hidden">
        <div className="line-clamp-6">
          {props.citation.formatted_text || props.citation.text}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

export const CitationView = (props: {
  relevantDocs: SearchDocName[];
  allowedDocTypeIds: string[];
  headerChildren: React.ReactNode;
  question: string;
  citations: Citation[];
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  onNewCitationSuccess: (
    citation: Citation,
    existingCitation: Citation | null
  ) => void;
  onDeleteCitationSuccess: (citation: Citation) => void;
  setDoc: (activeCitation: Citation | null) => void;
  labelText: string;
  onClickGenerate?: (citations: Citation[], departments: Department[]) => void;
  hideAtlasWidget?: boolean;
  persistUrl?: {
    prefix: string;
    suffix: string;
  };
  generateUrl?: {
    prefix: string;
    suffix: string;
  };
  simpleView?: boolean;
  docTypeSelector?: ReactNode;
  highlightedCitation: PdfCitation | null;
  setHighlightedCitation: React.Dispatch<
    React.SetStateAction<PdfCitation | null>
  >;
  baseDisplayHeaderChildren?: ReactNode;
}) => {
  const authInfo = useAuthInfo();
  const { docToView, setDocToView, setCitations, setPageNumber, docMetadata } =
    useContext(DocViewerContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const activeCitation = props.citations.find(
    (citation) => citation.id === props.activeCitationId
  );

  const citationsByDoc = props.citations.reduce(
    (
      acc: { [key: string]: { citations: Citation[]; name: string } },
      citation
    ) => {
      const docId = citation.doc_id || "Unknown Document";
      if (!acc[docId]) {
        acc[docId] = {
          citations: [],
          name: citation.doc_name || "Unknown Document",
        };
      }
      acc[docId].citations.push(citation);
      return acc;
    },
    {}
  );

  const removeCitation = async (citations: Citation[]) => {
    let response: boolean;
    if (props.persistUrl) {
      response = await deleteCitation(
        `${props.persistUrl.prefix}/citation/${props.persistUrl.suffix}`,
        citations.map((citation) => citation.id!),
        authInfo.accessToken ?? null
      );
    } else {
      response = true;
    }
    if (response === true) {
      citations.forEach((citation) => {
        props.onDeleteCitationSuccess(citation);
      });
    } else {
      toast.error("Failed to delete citation");
    }
  };

  const createPdfCitations = (
    citations: Citation[],
    showTooltip: boolean
  ): PdfCitation[] => {
    return citations.map((citation) => {
      const isSelected = citation.id === props.activeCitationId;
      return {
        id: citation.id || `cit-${citation.text.slice(0, 14)}`,
        match: citation.text,
        exactMatch: true,
        page: citation.page,
        className: `bg-highlight-${isSelected ? "default" : "inactive"}`,
        onClick: () => {
          props.setActiveCitationId(citation.id);
        },
        citationTooltip:
          isSelected && showTooltip ? (
            <div className="w-48">
              <HighlightTooltipActionButton
                text="Delete"
                explanationContent="Delete the existing citation"
                onClick={() => removeCitation([citation])}
              />
            </div>
          ) : null,
      };
    });
  };

  useEffect(() => {
    if (docToView?.docId) {
      const citationsToDisplay = createPdfCitations(
        props.citations.filter(
          (citation) => citation.doc_id === docToView.docId
        ),
        props.highlightedCitation === null
      );
      if (props.highlightedCitation) {
        citationsToDisplay.push(props.highlightedCitation);
      }
      setCitations(citationsToDisplay);
    } else {
      setCitations([]);
    }
  }, [
    props.citations,
    props.activeCitationId,
    docToView?.docId,
    props.highlightedCitation,
  ]);

  const copyCitationsToClipboard = () => {
    let text = "";
    if (props.citations.length > 0) {
      props.citations.forEach((citation) => {
        if (!text.includes(`Document: ${citation.doc_name}`)) {
          text += `Document: ${citation.doc_name}\n`;
        }
      });
    }
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const onSuccess = (citation: Citation) => {
    props.setActiveCitationId(citation.id);
    props.onNewCitationSuccess(citation, null);
  };

  const saveClick = async (citation: Citation) => {
    if (props.persistUrl) {
      const response = await addCitation(
        `${props.persistUrl.prefix}/citation/${props.persistUrl.suffix}`,
        citation,
        authInfo.accessToken ?? null
      );
      return response;
    } else {
      return Promise.resolve({
        citation_ids: [citation.id ?? uuidv4()],
        departments: [],
        source_text: citation.text,
        citation_start_indices: [0],
      });
    }
  };

  const generateClick = async () => {
    if (props.onClickGenerate && props.generateUrl) {
      let generatedCitationsLength = 0;
      setGenerateLoading(true);
      try {
        for await (const output of generateCitations(
          `${props.generateUrl.prefix}/citation-generate/${props.generateUrl.suffix}`,
          props.allowedDocTypeIds,
          authInfo.accessToken ?? null
        )) {
          props.onClickGenerate(output.citations, output.departments);
          if (activeCitation?.id !== output.citations[0].id) {
            props.setActiveCitationId(output.citations[0].id);
          }
          generatedCitationsLength = output.citations.length;
        }
        if (generatedCitationsLength === 0) {
          toast.info("No suggestions at the moment");
        }
      } catch (error: any) {
        toast.error("Failed to generate citations");
      } finally {
        setGenerateLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.highlightedCitation !== null) {
      const onClick = async (existingCitation: Citation | null) => {
        let citationToSave = {
          id: existingCitation?.id ?? null,
          page: props.highlightedCitation!.page,
          text: props.highlightedCitation!.match,
          doc_id: docMetadata?.id,
          doc_name: docMetadata?.name,
          formatted_text: null,
          created_at: new Date().toISOString().slice(0, -1),
          user: {
            id: authInfo.user?.userId ?? "",
            first_name: authInfo.user?.firstName ?? "",
            last_name: authInfo.user?.lastName ?? "",
            email: authInfo.user?.email ?? "",
          },
          start_index: null,
          match_index: props.highlightedCitation!.match_index ?? null,
        };
        const response = await saveClick(citationToSave);
        if (response !== null) {
          citationToSave.id = response.citation_ids[0];
          props.onNewCitationSuccess(citationToSave, existingCitation);
          props.setHighlightedCitation(null);
          setCitations((prev) => {
            return [
              ...prev.filter(
                (citation) => citation.id !== HIGHLIGHTED_CITATION_ID
              ),
            ];
          });
          if (
            response.citation_ids.length > 0 &&
            response.citation_ids[0] !== activeCitation?.id
          ) {
            props.setActiveCitationId(response.citation_ids[0]);
          }
          toast.success("Saved citation");
        } else {
          toast.error("Failed to save citation");
        }
      };
      setCitations((citations) => {
        return [
          ...citations.filter(
            (citation) => citation.id !== props.highlightedCitation!.id
          ),
          {
            ...props.highlightedCitation!,
            citationTooltip: (
              <div className="w-48">
                {activeCitation ? (
                  <>
                    <HighlightTooltipActionButton
                      text="Add New"
                      explanationContent="Add this as a new citation to the list"
                      onClick={() => onClick(null)}
                    />
                    <HighlightTooltipActionButton
                      text="Update"
                      explanationContent="Update the existing citation with the highlighted text"
                      onClick={() => onClick(activeCitation)}
                    />
                  </>
                ) : (
                  <HighlightTooltipActionButton
                    text="Add"
                    explanationContent="Add this citation to the list"
                    onClick={() => onClick(null)}
                  />
                )}
                <ClearHighlightTooltipActionButton
                  citationId={props.highlightedCitation!.id!}
                  additionalCallback={() => {
                    props.setHighlightedCitation(null);
                  }}
                />
              </div>
            ),
          },
        ];
      });
    }
  }, [props.highlightedCitation]);

  useEffect(() => {
    if (activeCitation) {
      props.setDoc(activeCitation);
      setPageNumber(activeCitation.page);
    } else {
      props.setDoc(null);
      setPageNumber(1);
    }
  }, [activeCitation?.id]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between pr-8">
        <div className="font-semibold text-lg">{props.labelText}</div>
        <div className="flex items-center space-x-2">
          {props.docTypeSelector}
          <NewCitation
            hideAtlasWidget={props.hideAtlasWidget}
            open={dialogOpen}
            saveClick={saveClick}
            setOpen={setDialogOpen}
            title="New Citation"
            question={props.question}
            successCallback={onSuccess}
            relevantDocs={props.relevantDocs}
            onCancel={() => {
              if (!props.activeCitationId && props.citations.length > 0) {
                props.setActiveCitationId(props.citations[0].id);
              }
            }}
            allowedDocTypeIds={props.allowedDocTypeIds}
            headerChildren={props.headerChildren}
          />
          {props.baseDisplayHeaderChildren}
          {props.onClickGenerate && (
            <Button
              variant="outline"
              onClick={generateClick}
              disabled={generateLoading || props.allowedDocTypeIds.length === 0}
              className="text-sm"
            >
              {generateLoading ? (
                <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <SparkleIcon className="w-4 h-4 mr-2" />
              )}
              Suggest Citations
            </Button>
          )}
          <Button
            variant="outline"
            onClick={() => {
              setDialogOpen(true);
            }}
            className="text-sm"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Add Citation
          </Button>
          {props.citations.length > 0 && !props.simpleView && (
            <Button variant="outline" onClick={copyCitationsToClipboard}>
              <CopyIcon className="w-4 h-4 mr-2" />
              <span className="text-sm">Copy</span>
            </Button>
          )}
        </div>
      </div>
      {props.simpleView && props.headerChildren}

      <div className="flex flex-col space-y-3 ">
        {Object.entries(citationsByDoc).map(([docId, { citations, name }]) => (
          <DocCitationGroupView
            key={docId}
            docName={name}
            citations={citations}
            active={docToView?.docId === docId}
            activeCitationId={props.activeCitationId}
            setActiveCitationId={props.setActiveCitationId}
            removeCitation={removeCitation}
            onClick={() => {
              setDocToView({ docId: docId });
              if (activeCitation?.doc_id !== docId) {
                props.setActiveCitationId(citations[0].id);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};
