import { useEffect, useState } from "react";
import { Factor } from "../../types";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../../shadcn/components/accordion";
import { Checkbox } from "../../shadcn/components/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Button } from "../../shadcn/components/button";
import { ChevronRight, Folder, FolderOpen, FileIcon } from "lucide-react";
import { cn } from "../../shadcn/lib/utils";

type FactorWithChecked = Factor & { checked: boolean };

type FactorTableDisplayProps = {
  factors: Factor[];
  onSubmit?: (factors: Factor[]) => void;
};

export const FactorTreeDisplay = ({
  factors,
  onSubmit,
}: FactorTableDisplayProps) => {
  const [factorsState, setFactorsState] = useState<FactorWithChecked[]>(
    factors.map((f) => ({ ...f, checked: true }))
  );

  const handleSubmit = () => {
    if (!onSubmit) {
      return;
    }
    onSubmit(
      factorsState
        .filter((f) => f.checked)
        .map((f) => {
          const { checked, ...rest } = f;
          return rest;
        })
    );
  };

  const onToggleFactor = (factorToToggle: FactorWithChecked) => {
    setFactorsState((prevFactors) =>
      prevFactors.map((f) =>
        f === factorToToggle ? { ...f, checked: !f.checked } : f
      )
    );
  };

  const onToggleAllFactors = (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => {
    setFactorsState((prev) =>
      prev.map((f) =>
        factorsToToggle.includes(f) ? { ...f, checked: check } : f
      )
    );
  };

  useEffect(() => {
    setFactorsState(factors.map((f) => ({ ...f, checked: true })));
  }, [factors]);

  const uniqueFunctionalAreas = Array.from(
    new Set(factorsState.map((factor) => factor.functional_area))
  );

  return (
    <div className="flex flex-col gap-2 h-full">
      {onSubmit && (
        <div className="flex justify-start gap-2 mb-2">
          <Button onClick={handleSubmit}>Add Factors to Audit</Button>
        </div>
      )}
      <div className="flex-1 overflow-y-auto">
        <div>
          {uniqueFunctionalAreas.map((functionalArea) => {
            const faFactors = factorsState.filter(
              (factor) => factor.functional_area === functionalArea
            );
            return (
              <FunctionalAreaDisplay
                key={functionalArea}
                functionalArea={functionalArea}
                factors={faFactors}
                onToggleFactor={onSubmit ? onToggleFactor : () => {}}
                onToggleAllFactors={onSubmit ? onToggleAllFactors : () => {}}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ FunctionalAreaDisplay ~~~~~~~~~~~~~~~~~~~~~
export const FunctionalAreaDisplay = ({
  functionalArea,
  factors,
  onToggleFactor,
  onToggleAllFactors,
}: {
  functionalArea: string;
  factors: FactorWithChecked[];
  onToggleFactor: (factor: FactorWithChecked) => void;
  onToggleAllFactors: (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => void;
}) => {
  const uniqueStandards = Array.from(
    new Set(factors.map((factor) => factor.standard_index ?? ""))
  );

  // Determine the checkbox state for this Functional Area
  const allChecked = factors.length > 0 && factors.every((f) => f.checked);
  const noneChecked = factors.every((f) => !f.checked);
  const isIndeterminate = !allChecked && !noneChecked;
  const checkedState: boolean | "indeterminate" = allChecked
    ? true
    : isIndeterminate
      ? "indeterminate"
      : false;

  const handleToggleAll = () => {
    onToggleAllFactors(factors, !allChecked);
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={functionalArea}
      className="border rounded-md bg-white"
    >
      <AccordionItem value={functionalArea} className="border-b-0">
        <AccordionTrigger className="flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 px-2 transition-colors">
          <div className="flex items-center w-full">
            <Checkbox
              aria-label={`Toggle all factors in ${functionalArea}`}
              className="ml-3 flex-shrink-0"
              onClick={(e) => e.stopPropagation()}
              checked={checkedState}
              onCheckedChange={handleToggleAll}
            />
            <span className="ml-2 font-medium text-gray-800 truncate">
              {functionalArea}
            </span>
          </div>
        </AccordionTrigger>

        <AccordionContent className="pl-6 pb-2">
          {/* Standards */}
          {uniqueStandards.map((standard) => {
            const standardFactors = factors.filter(
              (factor) => factor.standard_index === standard
            );
            return (
              <StandardDisplay
                key={standard}
                standard={standard}
                factors={standardFactors}
                onToggleFactor={onToggleFactor}
                onToggleAllFactors={onToggleAllFactors}
              />
            );
          })}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ StandardDisplay ~~~~~~~~~~~~~~~~~~~~~
const StandardDisplay = ({
  standard,
  factors,
  onToggleFactor,
  onToggleAllFactors,
}: {
  standard: string;
  factors: FactorWithChecked[];
  onToggleFactor: (factor: FactorWithChecked) => void;
  onToggleAllFactors: (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => void;
}) => {
  const uniqueElements = Array.from(
    new Set(factors.map((factor) => factor.element_index))
  );

  const allChecked = factors.length > 0 && factors.every((f) => f.checked);
  const noneChecked = factors.every((f) => !f.checked);
  const isIndeterminate = !allChecked && !noneChecked;
  const checkedState: boolean | "indeterminate" = allChecked
    ? true
    : isIndeterminate
      ? "indeterminate"
      : false;

  const handleToggleAll = () => {
    onToggleAllFactors(factors, !allChecked);
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={standard}
      className="bg-white"
    >
      <AccordionItem value={standard} className="border-b-0">
        <AccordionTrigger
          className={cn(
            "flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 px-1.5 transition-colors",
            checkedState ? "" : ""
          )}
        >
          <div className="flex items-center w-full">
            <Checkbox
              aria-label={`Toggle all factors in standard ${standard}`}
              checked={checkedState}
              onClick={(e) => e.stopPropagation()}
              onCheckedChange={handleToggleAll}
              className="ml-3 flex-shrink-0"
            />
            <span className="ml-2 text-gray-800 truncate">
              {standard || "NoStandard"}
            </span>
          </div>
        </AccordionTrigger>

        <AccordionContent className="pl-6 pb-2">
          {/* Elements */}
          {uniqueElements.map((element) => {
            const elementFactors = factors.filter(
              (factor) => factor.element_index === element
            );
            return (
              <ElementDisplay
                key={element}
                element={element}
                factors={elementFactors}
                onToggleFactor={onToggleFactor}
                onToggleAllFactors={onToggleAllFactors}
              />
            );
          })}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ ElementDisplay ~~~~~~~~~~~~~~~~~~~~~
const ElementDisplay = ({
  element,
  factors,
  onToggleFactor,
  onToggleAllFactors,
}: {
  element: string;
  factors: FactorWithChecked[];
  onToggleFactor: (factor: FactorWithChecked) => void;
  onToggleAllFactors: (
    factorsToToggle: FactorWithChecked[],
    check: boolean
  ) => void;
}) => {
  const allChecked = factors.length > 0 && factors.every((f) => f.checked);
  const noneChecked = factors.every((f) => !f.checked);
  const isIndeterminate = !allChecked && !noneChecked;
  const checkedState: boolean | "indeterminate" = allChecked
    ? true
    : isIndeterminate
      ? "indeterminate"
      : false;

  const handleToggleAll = () => {
    onToggleAllFactors(factors, !allChecked);
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={element}
      className="bg-white"
    >
      <AccordionItem value={element} className="border-b-0">
        <AccordionTrigger
          className={cn(
            "flex items-center cursor-pointer select-none hover:bg-gray-50 rounded-md py-1.5 px-1.5 transition-colors",
            checkedState ? "" : ""
          )}
        >
          <div className="flex items-center w-full">
            <Checkbox
              aria-label={`Toggle all factors in element ${element}`}
              checked={checkedState}
              onClick={(e) => e.stopPropagation()}
              onCheckedChange={handleToggleAll}
              className="ml-3 flex-shrink-0"
            />
            <span className="ml-2 text-gray-800 truncate">
              {element || "NoElement"}
            </span>
          </div>
        </AccordionTrigger>

        <AccordionContent className="pl-6 pb-2">
          {/* Individual factors */}
          {factors.map((factor) => (
            <FactorDisplay
              key={factor.factor_index}
              factor={factor}
              onToggleFactor={onToggleFactor}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~ FactorDisplay ~~~~~~~~~~~~~~~~~~~~~
export const FactorDisplay = ({
  factor,
  onToggleFactor,
}: {
  factor: FactorWithChecked;
  onToggleFactor: (factor: FactorWithChecked) => void;
}) => {
  return (
    <div
      className={cn(
        "flex items-center py-1.5 px-1.5 rounded-md transition-colors hover:bg-gray-50 pl-5",
        factor.checked ? "bg-blue-50 hover:bg-blue-100" : ""
      )}
      onClick={(e) => onToggleFactor(factor)}
    >
      {/* "File" icon instead of just a blank row */}
      <Checkbox
        aria-label="Select factor"
        checked={factor.checked}
        onCheckedChange={() => onToggleFactor(factor)}
        className="ml-2 flex-shrink-0"
      />
      <Tooltip>
        <TooltipTrigger>
          <span className="ml-2.5 text-gray-800 truncate">
            {factor.factor_title || "Untitled"}
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <span>{factor.factor_description ?? "Untitled"}</span>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
