import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  getUniqueFunctionalAreas,
  getUniqueStandards,
  getUniqueElements,
} from "./utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  auditFactorAssessment,
  AuditFactorAssessment,
  Factor,
} from "../../types";
import { AuditToolContext } from "../../contexts/AuditToolContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HeaderCell } from "../../components/Table";
import { useAuthInfo } from "@propelauth/react";
import {
  deleteAuditToolFactor,
  updateAuditFactor,
  updateAuditTool,
} from "../../utils/apiCalls";

import { Button } from "../../shadcn/components/button";

import { DataTable } from "../../components/Table";
import { toast } from "sonner";
import {
  AuditFactorAssessmentBadge,
  AuditFactorStatusBadge,
} from "./components";
import { CalendarIcon, PencilIcon, Trash2 } from "lucide-react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn/components/popover";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/dialog";

function randomDistributionOf100() {
  const met = Math.floor(Math.random() * 101);
  const partiallyMet = Math.floor(Math.random() * (101 - met));
  const notMet = 100 - (met + partiallyMet);

  return {
    met,
    partially_met: partiallyMet,
    not_met: notMet,
    total: 100,
  };
}

function aggregateAssessments(factors: Factor[]) {
  const counts: Record<AuditFactorAssessment, number> = {
    met: 0,
    not_met: 0,
    partially_met: 0,
    na: 0,
  };
  let total = 0;
  for (const factor of factors) {
    if (factor.assessment !== "na") {
      if (factor.assessment) {
        counts[factor.assessment] = (counts[factor.assessment] || 0) + 1;
      }
      total++;
    } else {
      counts["na"] = (counts["na"] || 0) + 1;
    }
  }
  return { counts, total };
}
interface AssessmentSummaryProps {
  factors: Factor[];
}

function DateRangePopover({
  startDate,
  endDate,
  onSubmit,
  label,
  open,
  setOpen,
}: {
  startDate: string;
  endDate: string;
  onSubmit: (startDate: string, endDate: string) => void;
  label: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [workingStartDate, setWorkingStartDate] = useState(dayjs(startDate));
  const [workingEndDate, setWorkingEndDate] = useState(dayjs(endDate));
  return (
    <div className="flex flex-col bg-white rounded-md p-2 border border-gray-200">
      <div className="text-xs text-gray-400 font-medium">{label}</div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button className="flex items-center gap-2 hover:bg-gray-100 rounded-md px-3 py-1.5 transition-colors">
            <span className="text-sm text-gray-600 font-medium">
              {startDate ? new Date(startDate).toLocaleDateString() : "N/A"} -{" "}
              {endDate ? new Date(endDate).toLocaleDateString() : "N/A"}
            </span>
            <PencilIcon className="h-4 w-4 text-gray-500" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-4 space-y-4">
          <DatePicker
            label="Start Date"
            value={workingStartDate}
            onChange={(newValue) => {
              if (newValue) {
                setWorkingStartDate(newValue);
              }
            }}
            className="w-full"
          />
          <DatePicker
            label="End Date"
            value={workingEndDate}
            onChange={(newValue) => {
              if (newValue) {
                setWorkingEndDate(newValue);
              }
            }}
            className="w-full"
          />
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4 rounded-md transition-colors"
            onClick={() => {
              onSubmit(
                workingStartDate.toISOString(),
                workingEndDate.toISOString()
              );
            }}
          >
            Apply Date Range
          </button>
        </PopoverContent>
      </Popover>
    </div>
  );
}
function AssessmentPercentageDisplay({ factors }: AssessmentSummaryProps) {
  const { counts, total } = useMemo(
    () => aggregateAssessments(factors),
    [factors]
  );
  const metCount = counts["met"] || 0;
  const naCount = counts["na"] || 0;
  const notMetCount = counts["not_met"] || 0;
  const partiallyMetCount = counts["partially_met"] || 0;

  const metPercentage = total > 0 ? (metCount / total) * 100 : 0;
  const naPercentage = total > 0 ? (naCount / total) * 100 : 0;
  const notMetPercentage = total > 0 ? (notMetCount / total) * 100 : 0;
  const partiallyMetPercentage =
    total > 0 ? (partiallyMetCount / total) * 100 : 0;

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-green-500"></div>
        <span className="font-medium">Met:</span> {metCount}/{total} (
        {metPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-yellow-400"></div>
        <span className="font-medium">Partially Met:</span> {partiallyMetCount}/
        {total} ({partiallyMetPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-red-500"></div>
        <span className="font-medium">Not Met:</span> {notMetCount}/{total} (
        {notMetPercentage.toFixed(1)}%)
      </div>
      <div className="flex items-center gap-2">
        <div
          className="w-4 h-4 bg-gray-300"
          style={{
            backgroundImage: `repeating-linear-gradient(
            45deg,
            #9ca3af,
            #9ca3af 10px,
            #6b7280 10px,
            #6b7280 20px
          )`,
          }}
        ></div>
        <span className="font-medium">N/A:</span> {naCount}/{total} (
        {naPercentage.toFixed(1)}%)
      </div>
    </div>
  );
}

export function AssesmentCircleDisplay({
  factors,
  displayText,
  size = 10,
}: AssessmentSummaryProps & { displayText?: string; size?: number }) {
  const { counts, total } = useMemo(
    () => aggregateAssessments(factors),
    [factors]
  );

  const metCount = counts["met"] || 0;
  const partiallyCount = counts["partially_met"] || 0;
  const notMetCount = counts["not_met"] || 0;

  const metPercentage = total > 0 ? (metCount / total) * 100 : 0;
  const partiallyPercentage = total > 0 ? (partiallyCount / total) * 100 : 0;

  const metStop = metPercentage;
  const partiallyStop = metPercentage + partiallyPercentage;

  const gradient = `conic-gradient(
    green 0% ${metStop}%,
    yellow ${metStop}% ${partiallyStop}%,
    red ${partiallyStop}% 100%
  )`;

  return (
    <div className="flex flex-col items-center space-y-2">
      <div
        className={`relative w-${size} h-${size} rounded-full`}
        style={{ background: gradient }}
        title={`Met: ${metCount}/${total}, Partially: ${partiallyCount}/${total}, Not Met: ${notMetCount}/${total}`}
      >
        <div className="absolute inset-2 bg-gray-200 rounded-full flex items-center justify-center text-sm font-medium">
          {displayText ?? ""}
        </div>
      </div>
    </div>
  );
}

export function AssessmentProgressBar({ factors }: AssessmentSummaryProps) {
  const { counts, total } = useMemo(
    () => aggregateAssessments(factors),
    [factors]
  );

  const metCount = counts["met"] || 0;
  const naCount = counts["na"] || 0;
  const notMetCount = counts["not_met"] || 0;
  const partiallyMetCount = counts["partially_met"] || 0;
  const incompleteCount =
    total - (metCount + naCount + notMetCount + partiallyMetCount);

  return (
    <div className="">
      {/* Stacked Bar */}
      <div className="flex justify-between items-center">
        <span className="text-sm text-gray-600">Progress</span>
        <span className="text-sm font-medium">{total} Factors</span>
      </div>
      <div className="w-full h-6 bg-white border border-gray-200 rounded overflow-hidden flex">
        {metCount > 0 && (
          <div
            className="bg-green-500 flex items-center justify-center text-white text-xs font-medium"
            style={{ width: `${(metCount / total) * 100}%` }}
            title={`Met: ${metCount}/${total}`}
          >
            {metCount}
          </div>
        )}
        {partiallyMetCount > 0 && (
          <div
            className="bg-yellow-400 flex items-center justify-center text-white text-xs font-medium"
            style={{ width: `${(partiallyMetCount / total) * 100}%` }}
            title={`Partially Met: ${partiallyMetCount}/${total}`}
          >
            {partiallyMetCount}
          </div>
        )}
        {notMetCount > 0 && (
          <div
            className="bg-red-500 flex items-center justify-center text-white text-xs font-medium"
            style={{ width: `${(notMetCount / total) * 100}%` }}
            title={`Not Met: ${notMetCount}/${total}`}
          >
            {notMetCount}
          </div>
        )}
        {naCount > 0 && (
          <div
            className="bg-gray-300 flex items-center justify-center text-gray-700 text-xs font-medium"
            style={{
              width: `${(naCount / total) * 100}%`,
              backgroundImage: `repeating-linear-gradient(
                45deg,
                #9ca3af,
                #9ca3af 10px,
                #6b7280 10px,
                #6b7280 20px
              )`,
            }}
            title={`N/A: ${naCount}/${total}`}
          >
            {naCount}
          </div>
        )}
        {incompleteCount > 0 && (
          <div
            className="bg-white flex items-center justify-center text-gray-600 text-xs font-medium"
            style={{ width: `${(incompleteCount / total) * 100}%` }}
            title={`Incomplete: ${incompleteCount}/${total}`}
          >
            {incompleteCount}
          </div>
        )}
      </div>
    </div>
  );
}

export function FactorDashboardDisplayTwo() {
  const { auditTool, setAuditTool } = useContext(AuditToolContext);
  const { accessToken } = useAuthInfo();
  const [lookbackPopoverOpen, setLookbackPopoverOpen] = useState(false);
  const [auditPeriodPopoverOpen, setAuditPeriodPopoverOpen] = useState(false);
  const functionalAreas = getUniqueFunctionalAreas(auditTool?.factors ?? []);
  const navigate = useNavigate();
  const handleUpdateLookbackPeriod = useCallback(
    async (startDate: string, endDate: string) => {
      if (!auditTool?.id || !accessToken) {
        console.error("Missing required audit tool ID or access token");
        return;
      }

      try {
        const tool = await updateAuditTool(
          auditTool.id,
          {
            audit_start_date: startDate,
            audit_end_date: endDate,
          },
          accessToken
        );

        setAuditTool((prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            ...tool,
          };
        });
        setLookbackPopoverOpen(false);
      } catch (error) {
        toast.error("Failed to update lookback period");
      }
    },
    [auditTool?.id, accessToken]
  );

  const handleUpdateAuditPeriod = useCallback(
    async (startDate: string, endDate: string) => {
      if (!auditTool?.id || !accessToken) {
        console.error("Missing required audit tool ID or access token");
        return;
      }

      try {
        const tool = await updateAuditTool(
          auditTool.id,
          {
            audit_work_start_date: startDate,
            audit_work_end_date: endDate,
          },
          accessToken
        );

        setAuditTool((prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            ...tool,
          };
        });
        setAuditPeriodPopoverOpen(false);
      } catch (error) {
        toast.error("Failed to update audit period");
      }
    },
    [auditTool?.id, accessToken]
  );
  return (
    <div className="space-y-8">
      {functionalAreas.map((fa) => {
        const faFactors = fa.factors;
        const standards = getUniqueStandards(faFactors);
        return (
          <div className="bg-gray-100 p-4 rounded-md">
            <div key={fa.functional_area} className="space-y-4">
              {/* Functional Area Header & Summary */}
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-bold">{fa.functional_area}</h2>
                  <div className="flex gap-4">
                    <DateRangePopover
                      label="Lookback Period"
                      startDate={auditTool?.audit_start_date ?? ""}
                      endDate={auditTool?.audit_end_date ?? ""}
                      onSubmit={(startDate, endDate) => {
                        handleUpdateLookbackPeriod(startDate, endDate);
                      }}
                      open={lookbackPopoverOpen}
                      setOpen={setLookbackPopoverOpen}
                    />
                    <DateRangePopover
                      label="Audit Period"
                      startDate={auditTool?.audit_work_start_date ?? ""}
                      endDate={auditTool?.audit_work_end_date ?? ""}
                      onSubmit={(startDate, endDate) => {
                        handleUpdateAuditPeriod(startDate, endDate);
                      }}
                      open={auditPeriodPopoverOpen}
                      setOpen={setAuditPeriodPopoverOpen}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <Tooltip>
                    <TooltipTrigger className="mb-4 w-full">
                      <AssessmentProgressBar factors={faFactors} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <AssessmentPercentageDisplay factors={faFactors} />
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
              {/* Standards within Functional Area */}
              <div className="space-y-6 pl-4">
                {standards.map((std) => {
                  const stdFactors = std.factors;
                  const elements = getUniqueElements(stdFactors);
                  return (
                    <div
                      key={std.standard_index}
                      className="space-y-4 rounded-md bg-gray-200 p-4"
                    >
                      <div className="flex items-center gap-2">
                        <h3 className="text-xl font-semibold">
                          {std.standard_index} - {std.standard_title}
                        </h3>
                      </div>

                      {/* Elements as table rows instead of cards */}
                      <div className="overflow-hidden">
                        <table className="min-w-full">
                          <tbody>
                            {elements.map((el, index) => (
                              <tr
                                key={el.element_index}
                                className={
                                  index < elements.length - 1
                                    ? "border-b border-gray-300"
                                    : ""
                                }
                              >
                                <td
                                  className="p-2"
                                  onClick={() => {
                                    navigate(
                                      `/delegate-audit/${auditTool?.id}/?fa=${fa.functional_area}&std=${std.standard_index}&el=${el.element_index}`
                                    );
                                  }}
                                >
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <div className="font-medium">
                                        {el.element_index} - {el.element_title}
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <div className="max-w-[200px] whitespace-normal break-words text-sm">
                                        {el.element_explanation}
                                      </div>
                                    </TooltipContent>
                                  </Tooltip>
                                </td>
                                <td className="p-2 w-[40%]">
                                  <div className="w-full space-y-2">
                                    <Tooltip>
                                      <TooltipTrigger className="w-full">
                                        <div className="hover:opacity-80 transition-opacity">
                                          <AssessmentProgressBar
                                            factors={el.factors}
                                          />
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <AssessmentPercentageDisplay
                                          factors={el.factors}
                                        />
                                      </TooltipContent>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const FactorTable = ({ factors }: { factors: Factor[] }) => {
  const { auditToolId } = useParams();
  const { accessToken } = useAuthInfo();
  const { setAuditTool } = useContext(AuditToolContext);
  const navigate = useNavigate();
  const navigateToFactorAudit = useCallback(
    (factorId: string) => {
      navigate(`/delegate-audit/${auditToolId}/factor/${factorId}/audit`);
    },
    [auditToolId]
  );
  const deleteFactor = useCallback(
    async (factorId: string) => {
      if (!auditToolId || !accessToken) {
        return;
      }
      await deleteAuditToolFactor(auditToolId, factorId, accessToken);
      setAuditTool((prev) => {
        if (!prev) {
          return prev;
        }
        const newFactors = prev.factors.filter((f) => f.id !== factorId);
        return {
          ...prev,
          factors: newFactors,
        };
      });
      toast.success("Factor deleted successfully");
    },
    [auditToolId, accessToken]
  );
  const columns: any[] = useMemo(() => {
    return [
      {
        accessorFn: (row: any) => row.factor_index,
        id: "factorIndex",
        header: ({ column }: { column: any }) => (
          <HeaderCell column={column} columnName="Index" />
        ),
        cell: ({ row }: { row: any }) => (
          <Link
            to={`/delegate-audit/${auditToolId}/factor/${row.original.id}/audit`}
            className="text-blue-600 hover:text-blue-800 hover:underline cursor-pointer"
          >
            <span>{row.original.factor_index}</span>
          </Link>
        ),
      },
      // {
      //   accessorFn: (row: any) => row.factor_title,
      //   id: "factorTitle",
      //   header: ({ column }: { column: any }) => (
      //     <HeaderCell column={column} columnName="Factor Title" />
      //   ),
      //   cell: ({ row }: { row: any }) => (
      //     <span>{row.original.factor_title}</span>
      //   ),
      // },
      // {
      //   accessorFn: (row: any) => row.factor_description,
      //   id: "factorDescription",
      //   header: ({ column }: { column: any }) => (
      //     <HeaderCell column={column} columnName="Factor Description" />
      //   ),
      //   cell: ({ row }: { row: any }) => (
      //     <Tooltip>
      //       <TooltipTrigger>
      //         <span className="line-clamp-3">
      //           {row.original.factor_description}
      //         </span>
      //       </TooltipTrigger>
      //       <TooltipContent>
      //         <div className="max-w-[250px] whitespace-normal break-words">
      //           {row.original.factor_explanation}
      //         </div>
      //       </TooltipContent>
      //     </Tooltip>
      //   ),
      // },
      {
        accessorFn: (row: any) => row.status,
        id: "status",
        header: ({ column }: { column: any }) => (
          <div className="h-[50px] flex items-center">Status</div>
        ),
        cell: ({ row }: { row: any }) => (
          <AuditFactorStatusBadge status={row.original.status} />
        ),
      },
      {
        accessorFn: (row: any) => row.assessment,
        id: "assessment",
        header: ({ column }: { column: any }) => (
          <div className="h-[50px] flex items-center">Assessment</div>
        ),
        cell: ({ row }: { row: any }) => (
          <AuditFactorAssessmentBadge status={row.original.assessment} />
        ),
      },
      {
        id: "actions",
        header: ({ column }: { column: any }) => (
          <HeaderCell column={column} columnName="" />
        ),
        cell: ({ row }: { row: any }) => (
          <div className="flex items-center justify-end gap-2">
            {/* <Button
              onClick={() => {
                navigateToFactorAudit(row.original.id);
              }}
            >
              Audit
            </Button> */}
            <Dialog>
              <DialogTrigger>
                <Button
                  variant="outline"
                  size="icon"
                  className="border-red-500 text-red-500 hover:bg-red-100 h-7 w-7"
                >
                  <Trash2 className="text-red-500 h-4 w-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    Are you sure you want to delete this factor?
                  </DialogTitle>
                  <DialogDescription>
                    This action cannot be undone.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button
                    variant="destructive"
                    onClick={() => deleteFactor(row.original.id)}
                  >
                    Delete
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <DataTable
      columns={columns}
      data={factors}
      paginationParams={{ pageSize: factors.length, pageIndex: 0 }}
      totalRecordCount={factors.length}
    />
  );
};
