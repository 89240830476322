import React from "react";
import {
  auditFactorAssessment,
  AuditFactorAssessment,
  auditFactorStatus,
  AuditFactorStatus,
} from "../../types";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../shadcn/components/select";

export const AuditFactorAssessmentBadge = ({
  status,
}: {
  status: AuditFactorAssessment | undefined;
}) => {
  if (!status) {
    return <></>;
  }
  const getStatusColor = (status: AuditFactorAssessment | undefined) => {
    switch (status) {
      case "met":
        return "bg-green-100 text-green-800 border-green-200";
      case "not_met":
        return "bg-red-100 text-red-800 border-red-200";
      case "partially_met":
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
      case "na":
        return "bg-gray-100 text-gray-800 border-gray-200";
      default:
        return "bg-gray-100 text-gray-800 border-gray-200";
    }
  };

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${getStatusColor(status)}`}
    >
      {status &&
      auditFactorAssessment[status as keyof typeof auditFactorAssessment]
        ? auditFactorAssessment[status as keyof typeof auditFactorAssessment]
        : "Todo"}
    </span>
  );
};

export const AuditFactorStatusBadge = ({
  status,
}: {
  status: AuditFactorStatus | undefined;
}) => {
  const getStatusColor = (status: AuditFactorStatus | undefined) => {
    switch (status) {
      case "todo":
        return "bg-red-100 text-red-800 border-red-200";
      case "review":
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
      case "done":
        return "bg-green-100 text-green-800 border-green-200";
      default:
        return "bg-red-100 text-red-800 border-red-200";
    }
  };
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${getStatusColor(status)}`}
    >
      {status && auditFactorStatus[status as keyof typeof auditFactorStatus]
        ? auditFactorStatus[status as keyof typeof auditFactorStatus]
        : "Todo"}
    </span>
  );
};

export const MarkdownToJSX = ({ markdown }: { markdown: string }) => {
  return markdown.split("\n").map((line, i, arr) => (
    <React.Fragment key={i}>
      {line}
      {i < arr.length - 1 && <br />}
    </React.Fragment>
  ));
};

export const FunctionalAreaSelect = ({
  functionalArea,
  handleChangeFunctionalArea,
}: {
  functionalArea: string;
  handleChangeFunctionalArea: (functionalArea: string) => void;
}) => {
  return (
    <Select value={functionalArea} onValueChange={handleChangeFunctionalArea}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select one..." />
      </SelectTrigger>
      <SelectContent>
        {[
          "Utilization Management",
          "Member Experience",
          "Population Health Management",
          "Health Equity",
          "Network Management",
          "Credentialing and Recredentialing",
          "Quality Management and Improvement",
        ].map((area) => (
          <SelectItem key={area} value={area}>
            {area}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
