import { useAuthInfo } from "@propelauth/react";
import { CopyIcon, Pencil1Icon, ReloadIcon } from "@radix-ui/react-icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  AnswerNav,
  BreadcrumbNav,
  QuestionToast,
} from "../../components/AuditPageComponents";
import { CitationView } from "../../components/Citation/Citations";
import { DocViewerCitation } from "../../components/DocViewer";
import { Layout } from "../../components/Layout";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import { NoteView } from "../../components/Notes";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import { StandardStatusSelector } from "../../components/StatusSelector";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../shadcn/components/button";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { Separator } from "../../shadcn/components/separator";
import { Textarea } from "../../shadcn/components/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  AuditQuestion,
  Citation,
  Department,
  Note,
  SearchDocName,
  TaskStatus,
} from "../../types";
import {
  createNarrative,
  debounce,
  getAuditQuestionAnswer,
  getAuditRelevantDocs,
  updateAuditQuestion,
  updateAuditQuestionDocTypesToSearch,
  updateQuestionAssignments,
} from "../../utils/apiCalls";
import { TimeAgo } from "../../utils/format";

const FilterDataSources = (props: {
  citations: Citation[];
  selectedDocs: string[];
  setSelectedDocs: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  // get counts of each doc_id, also include the doc_name
  const docCounts = props.citations.reduce(
    (acc, citation) => {
      acc[citation.doc_id!] = {
        count: (acc[citation.doc_id!]?.count ?? 0) + 1,
        name: citation.doc_name!,
      };
      return acc;
    },
    {} as Record<string, { count: number; name: string }>
  );

  return (
    <MultiSelectControl
      title="Filter Citations"
      items={Object.entries(docCounts).map(([docId, { count, name }]) => ({
        id: docId,
        name,
        count,
      }))}
      selectedItems={props.selectedDocs.map((docId) => ({
        id: docId,
        name: docCounts[docId]?.name ?? "",
        count: docCounts[docId]?.count ?? 0,
      }))}
      clearSelectedItems={() => {
        props.setSelectedDocs([]);
      }}
      selectItem={(item, isSelected) => {
        props.setSelectedDocs((prev) => {
          if (isSelected) {
            return [...prev.filter((docId) => docId !== item.id), item.id];
          }
          return prev.filter((citationId) => citationId !== item.id);
        });
      }}
      selectAll={() => {
        props.setSelectedDocs(
          props.citations.map((citation) => citation.doc_id!)
        );
      }}
      selectItemOnly={(item) => {
        props.setSelectedDocs([item.id]);
      }}
    />
  );
};

const SelectDataSources = (props: {
  auditResourceId: string;
  question: AuditQuestion;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { regDocGapDocTypes, internalDocTypes } = useContext(UserContext);

  const updateDocTypesToSearch = debounce((docTypeIds: string[]) => {
    updateAuditQuestionDocTypesToSearch(
      props.auditResourceId,
      props.question.id,
      docTypeIds,
      authInfo.accessToken ?? null
    );
  }, 1000);

  return (
    <MultiSelectControl
      title="Data Sources"
      items={internalDocTypes.map((docType) => ({
        id: docType.id,
        name: docType.name,
      }))}
      selectedItems={
        props.question.doc_types_to_search?.map((docTypeId) => ({
          id: docTypeId,
          name:
            internalDocTypes.find((docType) => docType.id === docTypeId)
              ?.name ?? "",
        })) ??
        regDocGapDocTypes.map((docType) => ({
          id: docType.id,
          name: docType.name,
        }))
      }
      clearSelectedItems={() => {
        props.setQuestion((prev) => {
          if (prev) {
            return {
              ...prev,
              doc_types_to_search: [],
            };
          }
          return prev;
        });
        updateDocTypesToSearch([]);
      }}
      selectItem={(item, isSelected) => {
        let newDocTypes: string[] = [];
        props.setQuestion((prev) => {
          if (prev) {
            newDocTypes = prev.doc_types_to_search ?? [];
            if (isSelected) {
              newDocTypes = [...newDocTypes, item.id];
            } else {
              newDocTypes = newDocTypes.filter((n) => n !== item.id);
            }
            return {
              ...prev,
              doc_types_to_search: newDocTypes,
            };
          }
          return prev;
        });
        updateDocTypesToSearch(newDocTypes);
      }}
      selectAll={() => {
        let newDocTypes: string[] = internalDocTypes.map(
          (docType) => docType.id
        );
        props.setQuestion((prev) => {
          if (prev) {
            return {
              ...prev,
              doc_types_to_search: newDocTypes,
            };
          }
          return prev;
        });
        updateDocTypesToSearch(newDocTypes);
      }}
      selectItemOnly={(item) => {
        props.setQuestion((prev) => {
          if (prev) {
            return {
              ...prev,
              doc_types_to_search: [item.id],
            };
          }
          return prev;
        });
        updateDocTypesToSearch([item.id]);
      }}
    />
  );
};

const AuditCitationView = (props: {
  auditQuestion: AuditQuestion;
  activeCitationId: string | null;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string | null>>;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
  doc: SearchDocName | null;
  setDoc: React.Dispatch<React.SetStateAction<SearchDocName | null>>;
  highlightedCitation: PdfCitation | null;
  setHighlightedCitation: React.Dispatch<
    React.SetStateAction<PdfCitation | null>
  >;
}) => {
  const authInfo = useAuthInfo();
  const { auditResourceId, questionId } = useParams();
  const { regDocGapDocTypes } = useContext(UserContext);
  const [relevantDocs, setRelevantDocs] = useState<SearchDocName[]>([]);
  const urlPrefix = `audit/answer`;
  const urlSuffix = `${auditResourceId}/${questionId}`;
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);

  const onSuccess = (citation: Citation, existingCitation: Citation | null) => {
    props.setQuestion((prev) => {
      if (prev) {
        let newCitations = [...prev.citations];
        if (existingCitation) {
          newCitations = newCitations.filter(
            (citation) => citation.id !== existingCitation!.id
          );
        }
        return {
          ...prev,
          citations: [...newCitations, citation],
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    const fetchRelevantDocs = async () => {
      const response = await getAuditRelevantDocs(
        auditResourceId ?? "",
        questionId ?? "",
        authInfo.accessToken ?? null
      );
      if (response !== null) {
        setRelevantDocs(response);
      } else {
        toast.error("Unable to get relevant documents");
      }
    };
    fetchRelevantDocs();
  }, [auditResourceId, questionId]);

  const onGenerateCitations = (
    citations: Citation[],
    departments: Department[]
  ) => {
    props.setQuestion((prev) => {
      if (prev) {
        return {
          ...prev,
          citations: [
            ...prev.citations.filter(
              (existingCitation) =>
                !citations.some(
                  (newCitation) => newCitation.text === existingCitation.text
                )
            ),
            ...citations,
          ],
          assignees: departments,
        };
      }
      return prev;
    });
  };

  return (
    <CitationView
      persistUrl={{
        prefix: urlPrefix,
        suffix: urlSuffix,
      }}
      generateUrl={{
        prefix: urlPrefix,
        suffix: urlSuffix,
      }}
      relevantDocs={relevantDocs}
      allowedDocTypeIds={
        props.auditQuestion.doc_types_to_search ??
        regDocGapDocTypes.map((docType) => docType.id)
      }
      question={props.auditQuestion.question}
      citations={props.auditQuestion.citations.filter(
        (citation) =>
          selectedDocs.includes(citation.doc_id!) || selectedDocs.length === 0
      )}
      highlightedCitation={props.highlightedCitation}
      setHighlightedCitation={props.setHighlightedCitation}
      activeCitationId={props.activeCitationId}
      setActiveCitationId={props.setActiveCitationId}
      onNewCitationSuccess={onSuccess}
      headerChildren={
        <Tooltip>
          <TooltipTrigger>
            <div className="text-left text-gray-500 text-sm">
              {props.auditQuestion.question.slice(0, 100)}
              {props.auditQuestion.question.length > 100 && "..."}
            </div>
          </TooltipTrigger>
          <TooltipContent className="max-w-[300px]">
            {props.auditQuestion.question}
          </TooltipContent>
        </Tooltip>
      }
      onDeleteCitationSuccess={(citation: Citation) => {
        props.setQuestion((prev) => {
          if (prev) {
            return {
              ...prev,
              citations: prev.citations.filter((c) => c.id !== citation.id),
            };
          }
          return prev;
        });
      }}
      labelText="Citations"
      setDoc={(ac) => {
        if (ac !== null && ac.doc_id !== props.doc?.id) {
          props.setDoc({
            id: null,
            doc_id: ac.doc_id!,
            name: ac.doc_name!,
            doc_type_name: "",
            additional_metadata: {},
            result_type: "filename",
            citation: null,
          });
        }
      }}
      onClickGenerate={onGenerateCitations}
      baseDisplayHeaderChildren={
        <div className="text-sm flex items-center space-x-2">
          <FilterDataSources
            citations={props.auditQuestion.citations}
            selectedDocs={selectedDocs}
            setSelectedDocs={setSelectedDocs}
          />
          <SelectDataSources
            auditResourceId={auditResourceId ?? ""}
            question={props.auditQuestion}
            setQuestion={props.setQuestion}
          />
        </div>
      }
    />
  );
};

export const NarrativeView = (props: {
  question: AuditQuestion;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
  updateQuestion: (narrative?: string, status?: TaskStatus) => Promise<void>;
}) => {
  const { auditResourceId, questionId } = useParams();
  const authInfo = useAuthInfo();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const cancelRef = useRef<boolean>(false);

  const debouncedUpdateQuestion = debounce(props.updateQuestion, 1000);

  const updateNarrative = async (narrative: string, skipSave: boolean) => {
    props.setQuestion((prev) => {
      if (prev) {
        return {
          ...prev,
          narrative,
          narrative_updated_at: new Date().toISOString().slice(0, -1),
          narrative_updated_by: {
            id: authInfo.user?.userId ?? "unknown",
            email: authInfo.user?.email ?? "unknown",
            first_name: authInfo.user?.firstName ?? "unknown",
            last_name: authInfo.user?.lastName ?? "unknown",
          },
        };
      }
      return prev;
    });

    if (!skipSave) {
      debouncedUpdateQuestion(narrative, props.question.status);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const generateNarrative = async () => {
    cancelRef.current = false;
    setGenerateLoading(true);
    try {
      for await (const output of createNarrative(
        auditResourceId ?? "",
        questionId ?? "",
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          break;
        }
        updateNarrative(output.narrative, true);
      }
    } catch (error: any) {
      console.error("There was an error generating the narrative", error);
      toast.error("Unable to generate narrative, please try again");
    } finally {
      setGenerateLoading(false);
    }
  };

  const copyNarrativeAnswerToClipboard = () => {
    if (props.question) {
      let text = props.question.narrative;
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    }
  };

  useEffect(() => {
    cancelRef.current = true;
  }, [questionId]);

  return (
    <>
      <div className="flex justify-between">
        <div className="font-semibold text-lg">Narrative Answer</div>
        <div className="space-x-2 flex-items-center">
          <Button variant="outline" onClick={generateNarrative}>
            {generateLoading ? (
              <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <Pencil1Icon className="w-4 h-4 mr-2" />
            )}
            <span className="text-sm">Draft</span>
          </Button>
          {props.question.narrative && (
            <Button variant="outline" onClick={copyNarrativeAnswerToClipboard}>
              <CopyIcon className="w-4 h-4 mr-2" />
              <span className="text-sm">Copy</span>
            </Button>
          )}
        </div>
      </div>
      <Textarea
        tabIndex={0}
        rows={10}
        value={props.question.narrative ?? ""}
        onChange={(e) => {
          updateNarrative(e.target.value, false);
        }}
        placeholder={`Enter updated text...`}
        spellCheck={false}
      />
    </>
  );
};

const AssignmentSelection = (props: {
  auditResourceId: string;
  question: AuditQuestion;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
}) => {
  const authInfo = useAuthInfo();
  const { departments } = useContext(UserContext);
  const updateAssignees = debounce(async (assignees: Department[]) => {
    await updateQuestionAssignments(
      props.auditResourceId,
      props.question.id,
      assignees,
      authInfo.accessToken ?? null
    );
  }, 1000);

  return (
    <MultiSelectControl
      title="Departments"
      selectedItems={props.question.assignees.map((u) => ({
        id: u.id,
        name: u.name,
      }))}
      items={departments}
      selectItem={(item, isSelected) => {
        let newAssignees: Department[] = [];
        props.setQuestion((prev) => {
          if (prev) {
            if (isSelected) {
              newAssignees = [
                ...prev.assignees,
                {
                  id: item.id,
                  name: item.name,
                },
              ];
            } else {
              newAssignees = prev.assignees.filter((u) => u.id !== item.id);
            }
            return {
              ...prev,
              assignees: newAssignees,
            };
          }
          return prev;
        });
        updateAssignees(newAssignees);
      }}
      clearSelectedItems={() => {
        props.setQuestion((prev) => {
          if (prev) {
            return {
              ...prev,
              assignees: [],
            };
          }
          return prev;
        });
        updateAssignees([]);
      }}
    />
  );
};

const AnswerStatusSelector = (props: {
  status: string;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
  updateQuestion: (narrative?: string, status?: TaskStatus) => Promise<void>;
}) => {
  const handleStatusChange = async (value: string) => {
    props.setQuestion((prev) => {
      if (prev) {
        return { ...prev, status: value as TaskStatus };
      }
      return prev;
    });
    await props.updateQuestion(undefined, value as TaskStatus);
  };

  return (
    <StandardStatusSelector
      status={props.status}
      handleStatusChange={handleStatusChange}
    />
  );
};

export const AuditAnswerView = () => {
  const { auditId, auditResourceId, questionId } = useParams();
  const authInfo = useAuthInfo();
  const [question, setQuestion] = useState<AuditQuestion | null>(null);
  const [activeCitationId, setActiveCitationId] = useState<string | null>(null);
  const [doc, setDoc] = useState<SearchDocName | null>(null);
  const [previousQuestionId, setPreviousQuestionId] = useState<string | null>(
    null
  );
  const [nextQuestionId, setNextQuestionId] = useState<string | null>(null);
  const noteUrlPrefix = `audit/answer`;
  const noteUrlSuffix = `${auditResourceId}/${questionId}`;
  const [highlightedCitation, setHighlightedCitation] =
    useState<PdfCitation | null>(null);

  const updateQuestion = async (narrative?: string, status?: TaskStatus) => {
    if (question && (narrative || status)) {
      const response = await updateAuditQuestion(
        auditResourceId ?? "",
        questionId ?? "",
        narrative ?? question.narrative,
        status ?? question.status,
        authInfo.accessToken ?? null
      );
      if (response !== null) {
        if (narrative) {
          setQuestion((prev) => {
            if (prev) {
              return {
                ...prev,
                narrative_updated_at: new Date().toISOString().slice(0, -1),
                narrative_updated_by: {
                  id: authInfo.user?.userId ?? "unknown",
                  email: authInfo.user?.email ?? "unknown",
                  first_name: authInfo.user?.firstName ?? "unknown",
                  last_name: authInfo.user?.lastName ?? "unknown",
                },
              };
            }
            return prev;
          });
        }
        if (status) {
          setQuestion((prev) => {
            if (prev) {
              return {
                ...prev,
                status_updated_at: new Date().toISOString().slice(0, -1),
                status_updated_by: {
                  id: authInfo.user?.userId ?? "unknown",
                  email: authInfo.user?.email ?? "unknown",
                  first_name: authInfo.user?.firstName ?? "unknown",
                  last_name: authInfo.user?.lastName ?? "unknown",
                },
              };
            }
            return prev;
          });
        }
      } else {
        toast.error("Failed to save answer");
      }
    }
  };

  useEffect(() => {
    if (auditResourceId && questionId) {
      getAuditQuestionAnswer(
        auditResourceId,
        questionId,
        authInfo.accessToken ?? null
      ).then((res) => {
        if (res !== null) {
          setQuestion(res.question);
          setPreviousQuestionId(res.previous_question_id);
          setNextQuestionId(res.next_question_id);
          setActiveCitationId(
            res.question.citations.length > 0
              ? res.question.citations[0].id
              : null
          );
        } else {
          toast.error("Failed to load existing answer, please refresh");
        }
      });
    }
  }, [auditResourceId, questionId]);

  const addNote = (note: Note) => {
    setQuestion((prev) => {
      if (prev) {
        return { ...prev, notes: [...prev.notes, note] };
      }
      return prev;
    });
  };

  const onNoteReact = (noteId: string, reaction: boolean) => {
    setQuestion((prev) => {
      if (prev) {
        const existingNoteReactions = prev.notes.find(
          (n) => n.id === noteId
        )?.reactions;
        if (existingNoteReactions) {
          const newNoteReactions = reaction
            ? [
                ...existingNoteReactions,
                {
                  user: {
                    id: authInfo.user?.userId ?? "unknown",
                    email: authInfo.user?.email ?? "unknown",
                    first_name: authInfo.user?.firstName ?? "unknown",
                    last_name: authInfo.user?.lastName ?? "unknown",
                  },
                  reaction: "U+1F44D",
                },
              ]
            : existingNoteReactions.filter(
                (r) => r.user.id !== authInfo.user?.userId
              );
          return {
            ...prev,
            notes: prev.notes.map((n) =>
              n.id === noteId ? { ...n, reactions: newNoteReactions } : n
            ),
          };
        }
        return prev;
      }
      return prev;
    });
  };

  const deleteNote = (noteId: string) => {
    setQuestion((prev) => {
      if (prev) {
        return {
          ...prev,
          notes: prev.notes.filter((n) => n.id !== noteId),
        };
      }
      return prev;
    });
  };

  return (
    <Layout pageName="Audits">
      <BreadcrumbNav
        auditId={auditId ?? ""}
        auditResourceId={auditResourceId ?? ""}
        review={false}
      />
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          defaultSize={40}
          minSize={40}
          maxSize={60}
          id="doc-view-panel"
          order={2}
        >
          {doc && (
            <DocViewerCitation
              docId={doc.doc_id}
              className="h-[calc(100vh-260px)]"
              hideAtlasWidget={true}
              onCitationsUpdate={(pdfCitation: PdfCitation) => {
                pdfCitation.className = "bg-highlight-context";
                setHighlightedCitation(pdfCitation);
              }}
            />
          )}
          {(!question || question.citations.length === 0) && (
            <div className="flex justify-center items-center h-full">
              <div className="text-center text-gray-500">No citations</div>
            </div>
          )}
        </ResizablePanel>
        {activeCitationId && <ResizableHandle withHandle className="mx-4" />}
        <ResizablePanel
          defaultSize={60}
          minSize={40}
          maxSize={60}
          id="resource-panel"
          order={3}
        >
          <div className="space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-165px)] overflow-y-auto">
            <Separator />
            {question && (
              <div className="space-y-2 pb-4">
                <AnswerNav
                  previousQuestionId={previousQuestionId}
                  nextQuestionId={nextQuestionId}
                  auditId={auditId ?? ""}
                  auditResourceId={auditResourceId ?? ""}
                  questionId={questionId ?? ""}
                  review={false}
                />
                <QuestionToast questionText={question.question}>
                  <div className="font-semibold underline text-lg">
                    {question.section_index}.&nbsp;&nbsp;
                    {question.section_title}
                  </div>
                  <div className="text-sm whitespace-pre-line pb-4">
                    {question.question_index}.&nbsp;&nbsp;{question.question}
                  </div>
                </QuestionToast>
                <div className="flex items-center justify-between pb-3 pr-2">
                  <AssignmentSelection
                    auditResourceId={auditResourceId ?? ""}
                    question={question}
                    setQuestion={setQuestion}
                  />
                  <AnswerStatusSelector
                    status={question?.status ?? "todo"}
                    setQuestion={setQuestion}
                    updateQuestion={updateQuestion}
                  />
                </div>
              </div>
            )}
            <Separator />
            {question && (
              <>
                <NarrativeView
                  question={question}
                  setQuestion={setQuestion}
                  updateQuestion={updateQuestion}
                />
                {question.narrative_updated_at && (
                  <div className="text-xs text-gray-500 text-center">
                    Last updated{" "}
                    <TimeAgo timestamp={question.narrative_updated_at} /> by{" "}
                    {`${question.narrative_updated_by.first_name}${question.narrative_updated_by.last_name ? ` ${question.narrative_updated_by.last_name[0]}.` : ""}`}
                  </div>
                )}
                <Separator />
                <AuditCitationView
                  doc={doc}
                  auditQuestion={question}
                  activeCitationId={activeCitationId}
                  setActiveCitationId={setActiveCitationId}
                  setQuestion={setQuestion}
                  setDoc={setDoc}
                  highlightedCitation={highlightedCitation}
                  setHighlightedCitation={setHighlightedCitation}
                />
                <Separator />
                <NoteView
                  notes={question.notes}
                  addNote={addNote}
                  onReact={onNoteReact}
                  deleteNote={deleteNote}
                  urlPrefix={noteUrlPrefix}
                  urlSuffix={noteUrlSuffix}
                />
              </>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
